import React, { FormEvent, useState } from 'react';
import styled from 'styled-components';
import StyledButton from '../../../ui/StyledButton';
import StyledH3 from '../../../ui/StyledH3';
import { StyledOptionalConsentObjectContainer } from '../../Checkbox';
import ProductIcon from '../ProductIcon';
import { saveAs } from 'file-saver';
import { Icon } from '../../../../context/ProductProvider';

const StyledDocumentCheckboxContainer = styled(
  StyledOptionalConsentObjectContainer,
)`
  flex-direction: column;
`;

const StyledRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 799px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const StyledButtonArea = styled(StyledRowContainer)`
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.color.inputBackground};

  @media screen and (max-width: 799px) {
    padding-bottom: 10px;
  }
`;

const StyledSelectionArea = styled(StyledRowContainer)`
  padding-top: 20px;
  flex-wrap: wrap;
`;

const StyledIconHeaderArea = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;
const HiddenRequiredHelperCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
`;
export interface DocumentCheckboxProps {
  description: string;
  infoUrl: string;
  onDownloaded: () => void;
  title: string;
  icon: Icon;
  required: boolean;
}

const DocumentCheckbox: React.FC<DocumentCheckboxProps> = ({
  title,
  icon,
  description,
  infoUrl,
  children,
  onDownloaded,
  required,
}) => {
  const [isDownloaded, setIsDownloaded] = useState(false);
  const handleDownloadClick = (e: FormEvent) => {
    setIsDownloaded(true);
    e.preventDefault();
    if (infoUrl) {
      const isMobileOrFirefox = /iPhone|iPad|iPod|webOS|Android|Firefox/i.test(
        navigator.userAgent,
      );
      if (isMobileOrFirefox) {
        window.open(infoUrl);
      } else {
        const fileName = infoUrl.split('/').pop();
        saveAs(infoUrl, fileName);
      }
    }
    onDownloaded();
  };
  return (
    <StyledDocumentCheckboxContainer>
      <StyledIconHeaderArea>
        <ProductIcon icon={icon} />
        <StyledH3>{title}</StyledH3>
      </StyledIconHeaderArea>
      <StyledButtonArea>
        <p
          dangerouslySetInnerHTML={{
            __html: description || '',
          }}
        />
        <HiddenRequiredHelperCheckbox
          aria-hidden={true}
          checked={isDownloaded}
          required={required}
        />
        <StyledButton onClick={handleDownloadClick}>Herunterladen</StyledButton>
      </StyledButtonArea>
      <StyledSelectionArea>{children}</StyledSelectionArea>
    </StyledDocumentCheckboxContainer>
  );
};

export default DocumentCheckbox;
