import React from 'react';
import styled from 'styled-components';

interface StyledSummaryDetailProps {
  description: React.ReactNode;
  title: React.ReactNode;
  open?: boolean;
}

const StyledDetails = styled.details`
  background-color: #f3f3f3;
  border-bottom: 1px solid #e5e5e5;

  &[open] .arrow {
    color: red;
    rotate: 225deg;
  }
`;
const StyledSummary = styled.summary`
  background-color: white;
  display: flex;
  justify-content: space-between;
  list-style: none;
  font-weight: bold;
  padding: 25px 20px;
`;

const StyledArrow = styled.div`
  height: 15px;
  min-width: 15px;
  border: 2px solid ${(props) => props.theme.color.primary};
  border-top: none;
  border-left: none;
  rotate: 45deg;
  transition: 0.3s;
  margin: 0 10px;
`;

const StyledSummaryDetail = ({
  title,
  description,
  open = false,
}: StyledSummaryDetailProps) => {
  return (
    <StyledDetails open={open}>
      <StyledSummary>
        {title} <StyledArrow className="arrow" />
      </StyledSummary>
      {description}
    </StyledDetails>
  );
};

export default StyledSummaryDetail;
