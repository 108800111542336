import React from 'react';

import ReactNumberFormat from 'react-number-format';

type NumberFormatProps = {
  price?: number;
  prefix?: string;
  suffix?: string;
};

const NumberFormat = ({ price, prefix, suffix }: NumberFormatProps) => {
  const euroSuffix = ` € ${suffix || ''}`;
  return (
    <ReactNumberFormat
      value={price}
      displayType="text"
      decimalSeparator=","
      suffix={euroSuffix}
      decimalScale={2}
      fixedDecimalScale
      prefix={prefix}
    />
  );
};

export default NumberFormat;
