import React from 'react';
import { GDPRObjectType } from '../../../../context/ProductProvider';
import useContent from '../../../../context/ContentProvider/useContent';
import styled from 'styled-components';
import StyledSummaryDetail from '../../../ui/StyledSummaryDetail/StyledSummaryDetail';
import GDPRSelection from '../../../products/GDPRSelection/GDPRSelection';
import ProductIcon from '../../../products/shared/ProductIcon/ProductIcon';
import StyledH3 from '../../../ui/StyledH3/StyledH3';
import StyledParagraph from './components/StyledParagraph';
import StyledButton, { ButtonTheme } from '../../../ui/StyledButton';

type GDPRSectionProps = {
  gdprObjects: GDPRObjectType[];
  onCheckboxCheck: (type: string) => void;
  onSelectForAll: (select: boolean) => void;
  selectedIds: string[];
};

const StyledGDPRContainer = styled.div`
  background-color: white;
  box-sizing: border-box;
  border-radius: 14px;
  margin: 20px 0;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
`;

const StyledHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 25px 20px;
    // for all not first HeaderWrappers
    &:not(:first-child) {
    border-bottom: 1px solid grey;
    }
}`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 25px;
  margin: 35px 0;
  flex-wrap: wrap;
  gap: 1rem;
`;

const StyledParagraphWrapper = styled.div`
  padding: 0 25px;
`;

const StyledDetailsWrapper = styled.div`
  padding: 40px;
`;

const StyledRightAlignedButton = styled(StyledButton)`
  margin: 20px 15px; 0 0;
  align-self: flex-end;
`;

const GDPRSection = ({
  gdprObjects,
  onCheckboxCheck,
  selectedIds,
  onSelectForAll,
}: GDPRSectionProps) => {
  const openDialogs = () => {
    const details = document.querySelectorAll(
      '#gdpr details',
    ) as NodeListOf<HTMLDetailsElement>;
    details.forEach((detail) => {
      detail.open = true;
    });
  };

  const isCheckboxChecked = (id: string) => {
    return selectedIds.includes(id);
  };
  const sortedGDPRObjects = gdprObjects.sort(
    (a, b) => a.consent_object.sort_order - b.consent_object.sort_order,
  );

  const mainGDPRObject = gdprObjects.find(
    (x) =>
      x.consent_object.sort_order === 10 || x.consent_object.sort_order === 1,
  );

  const { data } = useContent();

  return (
    <StyledGDPRContainer id="gdpr">
      <StyledHeaderWrapper>
        <ProductIcon icon={sortedGDPRObjects[0].consent_object.icon_type} />
        <StyledH3>{data?.gdpr?.gdprTitle}</StyledH3>
      </StyledHeaderWrapper>
      <StyledParagraphWrapper>
        <StyledParagraph
          dangerouslySetInnerHTML={{
            __html: data?.gdpr?.gdprDescription || '',
          }}
        ></StyledParagraph>
      </StyledParagraphWrapper>
      {data?.gdpr?.downloadUrl && (
        <StyledRightAlignedButton
          buttonTheme={ButtonTheme.PRIMARY}
          type="button"
          onClick={() => {
            window.open(data?.gdpr?.downloadUrl, '_blank');
          }}
        >
          {data?.gdpr?.downloadText}
        </StyledRightAlignedButton>
      )}
      <StyledHeaderWrapper>
        <ProductIcon icon={'SWITCH'} />
        <StyledH3>{data?.gdpr?.gdprSubheader}</StyledH3>
      </StyledHeaderWrapper>
      {sortedGDPRObjects.map((product) => {
        const { title, description, sort_order } = product.consent_object;
        return (
          <GDPRSelection
            open={data?.gdpr?.initialOpen}
            key={sort_order}
            title={title}
            description={description}
            label={data?.gdpr?.selectLabel?.replace('{title}', title) || ''}
            checked={isCheckboxChecked(product.uniqueId)}
            onChange={() => onCheckboxCheck(product.uniqueId)}
            disabled={
              // disable all checkboxes if the mainObject is still present and not checked
              mainGDPRObject && product.uniqueId !== mainGDPRObject.uniqueId
                ? !isCheckboxChecked(mainGDPRObject.uniqueId)
                : false
            }
          />
        );
      })}
      <StyledHeaderWrapper>
        <ProductIcon icon={'DOCUMENT'} />
        <StyledH3>{data?.gdpr?.gdprFAQHeader}</StyledH3>
      </StyledHeaderWrapper>
      {data?.gdpr?.additionalInfos &&
        data.gdpr.additionalInfos?.map((info, index) => (
          <StyledSummaryDetail
            key={index}
            title={info.title}
            description={
              <StyledDetailsWrapper>
                <StyledParagraph
                  dangerouslySetInnerHTML={{ __html: info.description }}
                ></StyledParagraph>
              </StyledDetailsWrapper>
            }
          />
        ))}
      <StyledButtonWrapper>
        <StyledButton
          type="button"
          buttonTheme={ButtonTheme.PRIMARY_OUTLINED}
          onClick={() => onSelectForAll(false)}
        >
          {data?.gdpr?.buttonUnselectAll || ''}
        </StyledButton>
        <StyledButton
          type="button"
          buttonTheme={ButtonTheme.PRIMARY_OUTLINED}
          onClick={openDialogs}
        >
          {data?.gdpr?.buttonOpenAllDetails || ''}
        </StyledButton>
        <StyledButton onClick={() => onSelectForAll(true)} type="button">
          {data?.gdpr?.buttonSelectAll || ''}
        </StyledButton>
      </StyledButtonWrapper>
    </StyledGDPRContainer>
  );
};

export default GDPRSection;
