import React from 'react';
import StyledList from '../../../ui/StyledList';
import { DocumentCheckboxType } from '../../../../context/ProductProvider';
import DocumentCheckbox from '../../../products/DocumentCheckbox';

type DocumentCheckboxSectionProps = {
  documentCheckboxObjects: DocumentCheckboxType[];
  onDocumentCheckboxCheck: (type: string) => void;
  selectedIds: string[];
};

const CheckboxSection = ({
  documentCheckboxObjects,
  onDocumentCheckboxCheck,
  selectedIds,
}: DocumentCheckboxSectionProps) => {
  const isDocumentCheckboxChecked = (id: string) => {
    return selectedIds.includes(id);
  };

  return (
    <StyledList>
      {documentCheckboxObjects.map((product) => {
        const { title, description, icon_type, required, info_url } =
          product.consent_object;
        return (
          <DocumentCheckbox
            title={title}
            description={description}
            icon={icon_type}
            infoUrl={info_url}
            id={product.uniqueId}
            required={required}
            key={product.uniqueId}
            onCheckboxCheck={onDocumentCheckboxCheck}
            isChecked={isDocumentCheckboxChecked(product.uniqueId)}
          />
        );
      })}
    </StyledList>
  );
};

export default CheckboxSection;
