import React from 'react';
interface InformationTextProps {
  informationText: string;
}

export const InformationText: React.FC<InformationTextProps> = ({ informationText }) => {
  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: informationText || '' }} />
    </>
  );
};