import React from 'react';
import styled from 'styled-components';

export interface StyledIconWrapperProps {
  color?: string;
  width?: string;
  height?: string;
  alt?: string;
  title?: string;
  onClick?: () => void;
}

interface StyledIconProps extends StyledIconWrapperProps {
  src: React.FC;
}

const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  & svg {
    path {
      stroke: ${(props) => props.color || props.theme.color.primary};
    }
    ${(props) => props.width && `width: ${props.width};`}
  }
  display: flex;
`;

const StyledIcon: React.FC<StyledIconProps> = ({ src: Icon, ...rest }) => {
  return (
    <StyledIconWrapper {...rest}>
      <Icon />
    </StyledIconWrapper>
  );
};

export default StyledIcon;
