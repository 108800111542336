import styled from 'styled-components';

interface ContentProps {
  small?: boolean;
}

const StyledContent = styled.div<ContentProps>`
  position: relative;
  max-width: 980px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${(props) => (props.small ? '20px 60px' : '40px 60px')};

  @media screen and (max-width: 799px) {
    padding: 8px 16px;
    
  }
`;

export default StyledContent;
