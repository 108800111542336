import { useCallback, useState, useMemo } from 'react';
import { CheckboxType } from '../../../context/ProductProvider';
import { useGetProducts } from '../../../context/ProductProvider/useProducts';
import { isCheckboxObject, isObjectRequired, getCheckedList } from '../utils';

const getSelectedCheckboxObjects = (
  checkboxObjects: CheckboxType[],
  selectedIds: string[],
) => {
  return selectedIds.reduce<CheckboxType[]>((prev, selectedId) => {
    const selectedCheckbox = checkboxObjects.find(
      (checkbox) => checkbox.uniqueId === selectedId,
    );
    if (selectedCheckbox) {
      return [...prev, selectedCheckbox];
    }
    return prev;
  }, []);
};

const useCheckbox = () => {
  const { data } = useGetProducts();
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState<string[]>([]);

  const checkboxObjects = useMemo(
    () => data?.filter<CheckboxType>(isCheckboxObject) || [],
    [data],
  );
  const requiredCheckbox = useMemo(
    () => checkboxObjects?.filter(isObjectRequired) || [],
    [checkboxObjects],
  );
  const optionalCheckbox = useMemo(
    () =>
      checkboxObjects?.filter((product) => !isObjectRequired(product)) || [],
    [checkboxObjects],
  );

  const onCheckboxCheck = useCallback(
    (id: string) => {
      setSelectedCheckboxIds(getCheckedList(selectedCheckboxIds, id));
    },
    [selectedCheckboxIds],
  );

  const selectedCheckboxObjects = getSelectedCheckboxObjects(
    checkboxObjects,
    selectedCheckboxIds,
  );

  return {
    requiredCheckbox,
    optionalCheckbox,
    onCheckboxCheck,
    selectedCheckboxIds,
    selectedCheckboxObjects,
  };
};

export { useCheckbox };
