export type ProductOption = {
  id: string;
  title: string;
  price: number;
  order: number;
  price_interval: PriceIntervals;
  created_at: Date;
  updated_at: Date;
};

type ConsentObject = {
  id: string;
  type:
    | 'DEFAULT'
    | 'SELECT'
    | 'CHECKBOX'
    | 'DOCUMENT'
    | 'ADVERTISEMENT'
    | 'INPUT'
    | 'DOCUMENT_CHECKBOX'
    | 'CONFIRMATION'
    | 'DOCUMENT_RADIOBUTTON'
    | 'PRODUCT_UPDATE'
    | 'DSGVO';

  title: string;
  icon_type: Icon;
  description: string;
  info_url: string;
  required: boolean;
  price: number;
  price_interval: PriceIntervals;
  options: ProductOption[];
  created_at: Date;
  updated_at: Date;
};

type Product = {
  uniqueId: string;
  account_number: string;
  status: number;
  created_at: Date;
  updated_at: Date;
  consent_object: ConsentObject;
  consent_object_option: ProductOption | null;
};

export type FetchedProductData = Omit<Product, 'uniqueId'>;

export type MappedProduct = Omit<Product, 'uniqueId'> & {
  uniqueId?: string;
};

export type DefaultType = Product & {
  consent_object_option: null;
  bonus: number;
  consent_object: {
    type: 'DEFAULT';
    required: true;
  };
};

export type DropdownType = Product & {
  consent_object_option: ProductOption;
  bonus: number;
  consent_object: {
    type: 'SELECT';
    required: true;
  };
};

export type CheckboxType = Product & {
  consent_object_option: null;
  consent_object: {
    type: 'CHECKBOX';
    required: boolean;
  };
};

export type DocumentType = Product & {
  consent_object_option: null;
  consent_object: {
    type: 'DOCUMENT';
    required: true;
  };
};

export type AdvertisementType = Product & {
  consent_object_option: null;
  consent_object: {
    type: 'ADVERTISEMENT';
  };
};

export type InputType = Product & {
  consent_object_option: null;
  custom_data: string;
  consent_object: {
    type: 'INPUT';
    validation: string;
  };
};

export type DocumentCheckboxType = Product & {
  consent_object_option: null;
  consent_object: {
    type: 'DOCUMENT_CHECKBOX';
    required: boolean;
  };
};

export type ConfirmationType = Product & {
  consent_object_option: null;
  consent_object: {
    type: 'CONFIRMATION';
  };
};

export type DocumentRadioButtonType = Product & {
  consent_object_option: null;
  custom_data: string;
  consent_object: {
    type: 'DOCUMENT_RADIOBUTTON';
  };
};

export type GDPRObjectType = Product & {
  consent_object_option: null;
  consent_object: {
    type: 'DSGVO';
    sort_order: number;
  };
};

export type ProductUpdateType = Product & {
  consent_object_option: null;
  consent_object: {
    type: 'PRODUCT_UPDATE';
    required: true;
    options: ProductOption[];
  };
};

export type ProductData =
  | DefaultType
  | DropdownType
  | CheckboxType
  | DocumentType
  | AdvertisementType
  | InputType
  | DocumentCheckboxType
  | ConfirmationType
  | DocumentRadioButtonType
  | ProductUpdateType
  | GDPRObjectType;

export type Icon =
  | 'CARD'
  | 'BANK'
  | 'LOCKER'
  | 'MAIL'
  | 'DOCUMENT'
  | 'TRIANGLE'
  | 'CONTRACT'
  | 'TELEPHONE'
  | 'SWITCH';

export enum PriceIntervalsEnum {
  MONTHLY = 'monatlich',
  YEARLY = 'jährlich',
  QUARTERLY = 'vierteljährlich',
  CM_PER_YEAR = 'je cm pro Jahr',
}
export type PriceIntervals = keyof typeof PriceIntervalsEnum;
