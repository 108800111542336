import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/source-sans-pro-v21-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/source-sans-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/source-sans-pro-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/source-sans-pro-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/source-sans-pro-v21-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-700 - latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/source-sans-pro-v21-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/source-sans-pro-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/source-sans-pro-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/source-sans-pro-v21-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/source-sans-pro-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/source-sans-pro-v21-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
    #app {
        height: 100vh;
        width: 100vw;
        font-family: 'Source Sans Pro', Arial, sans-serif;
    }
    main {
        position: relative;
    }

`;

export default GlobalStyle;
