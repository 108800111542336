import styled from 'styled-components';
import React from 'react';
import { DocumentRadioButtonProps } from '../../products/DocumentRadioButton';
import StyledLabel from '../StyledLabel';

const StyledRadioButtonWrapper = styled.div``;

const RadioButton = styled.input.attrs<DocumentRadioButtonProps>({
  type: 'radio',
})`
  border: 1px solid orange;
  opacity: 0;

  & + ${StyledLabel} {
    display: block;
    width: 100px;
    text-align: center;
  }
  &:checked + ${StyledLabel} {
    background-color: ${(props) => props.theme.color.secondary};
    color: ${(props) => props.theme.color.white};
  }
  &:disabled + ${StyledLabel} {
    border-color: ${(props) => props.theme.color.inputDisabled};
    color: ${(props) => props.theme.color.inputDisabled};
  }
`;

interface RadioButtonProps {
  id: string;
  name: string;
  disabled: boolean;
  label?: string;
  handleClick: (event: React.MouseEvent<HTMLLabelElement>) => void;
}

const StyledRadioButton: React.FC<RadioButtonProps> = ({
  id,
  disabled,
  name,
  label,
  handleClick,
}) => {
  return (
    <StyledRadioButtonWrapper>
      <RadioButton
        name={name}
        id={id}
        value={name}
        disabled={disabled}
        required={true}
      />
      <StyledLabel onClick={handleClick} border htmlFor={id}>
        {label}
      </StyledLabel>
    </StyledRadioButtonWrapper>
  );
};

export default StyledRadioButton;
