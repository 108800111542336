import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../context/AuthProvider';
import { User } from '../../context/AuthProvider/types';
import useContent from '../../context/ContentProvider/useContent';
import StyledButton from '../ui/StyledButton';

const StyledLoginSection = styled.section`
  position: relative;
  left: 0;
  right: 0;
  top: -60px;
  max-width: 940px;
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing.s};
  border-radius: 14px;
  box-shadow: 0 25px 30px 0 rgba(0, 0, 0, 0.14);
  background-color: ${(props) => props.theme.color.white};
  box-sizing: border-box;

  @media screen and (max-width: 799px) {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;

    * > :last-child {
      margin-top: 10px;
    }
  }
`;

const StyledLoginWrapper = styled.div`
position: relative;
`;

const StyledLoginForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 799px) {
    flex-direction: column;
    padding: 0 40px;
    width: 100%;
  }
`;

const StyledIdInput = styled.input`
  flex-basis: 25%;
  margin: 0 10px 0 0;
  padding: 15px;
  border-radius: 7px;
  background-color: ${(props) => props.theme.color.inputBackground};
  color: ${(props) => props.theme.color.black};
  border-style: none;

  @media screen and (max-width: 799px) {
    margin: 0 0 5px 0;
    width: 100%;
    box-sizing: border-box;
  }
`;

const StyledCodeInput = styled(StyledIdInput)`
  flex-basis: 0;
  margin: 0 auto 0 0;

  @media screen and (max-width: 799px) {
    margin: 0 0 5px 0;
  }
`;

const StyledInputHint = styled.p`
  margin: 25px 0 0 14px;
  font-size: ${(props) => props.theme.font.size.normal};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.color.black};

  @media screen and (max-width: 799px) {
    margin: 10px 0 0 0;
  }
`;

const StyledErrorMessage = styled.div`
 color:red;
 background-color: #FFCCCC;
 padding: 15px;
 margin-bottom: 10px;
 border-radius: 6px;
`;

const Login: React.FC = () => {
  const { loading: loggingIn, doLogin } = useAuth();
  const { data } = useContent();
  const [approvalId, setApprovalId] = useState<string>('');
  const [accessCode, setAccessCode] = useState<string>('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loggingIn) {
      return;
    }
    const user: User = {
      username: approvalId,
      password: accessCode,
    }
    doLogin( user );
  };

  return (
    <StyledLoginWrapper>
      <StyledLoginSection>
        {data?.landingPage.hero.maintenanceMessage && <StyledErrorMessage dangerouslySetInnerHTML={{__html: data?.landingPage.hero.maintenanceMessage || ''}}/>}
        <StyledLoginForm onSubmit={handleSubmit}>
          <StyledIdInput
            required
            placeholder={data?.landingPage?.hero?.idInputPlaceholder}
            value={approvalId}
            onChange={(e) => setApprovalId(e.target.value)}
          />
          <StyledCodeInput
            required
            placeholder={data?.landingPage?.hero?.codeInputPlaceholder}
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
          />
          
          <StyledButton type="submit" loading={loggingIn}>
            Fortfahren
          </StyledButton>
        </StyledLoginForm>
        <StyledInputHint>
          {data?.landingPage?.hero?.inputParagraph}
        </StyledInputHint>
      </StyledLoginSection>
    </StyledLoginWrapper>
  );
};

export default Login;
