import React from 'react';
import Header from '../../Header';
import Hero from '../../Hero';
import ConsentArea from '../../ConsentArea';
import Footer from '../../Footer';
import styled from 'styled-components';
import StyledContent from '../../layout/StyledContent';
import StyledH2 from '../../ui/StyledH2';
import useContent from '../../../context/ContentProvider/useContent';

const StyledConsentText = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.color.black};
  max-width: 80%;
`;

const ProductPage: React.FC<{}> = () => {
  const { data } = useContent();

  return (
    <>
      <Header />
      <main>
        <Hero collapsed />
        <section>
          <StyledContent>
            <StyledH2
              dangerouslySetInnerHTML={{ __html: data?.product?.title || '' }}
            />
            <StyledConsentText
              dangerouslySetInnerHTML={{
                __html: data?.product?.paragraph || '',
              }}
            />
          </StyledContent>
        </section>
        <ConsentArea />
      </main>
      <Footer />
    </>
  );
};

export default ProductPage;
