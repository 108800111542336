import { useCallback, useState } from 'react';

const useConsentModals = () => {
  const [isModalActive, setModalActive] = useState(false);
  const [isThanksModalActive, setThanksModalActive] = useState(false);

  const closeModal = useCallback(() => {
    setModalActive(false);
  }, []);

  const closeThanksModal = useCallback(() => {
    setThanksModalActive(false);
  }, []);

  const showModal = useCallback(() => {
    closeThanksModal();
    setModalActive(true);
  }, [closeThanksModal]);

  const showThanksModal = useCallback(() => {
    closeModal();
    setThanksModalActive(true);
  }, [closeModal]);

  return {
    isModalActive,
    isThanksModalActive,
    showModal,
    closeModal,
    closeThanksModal,
    showThanksModal,
  };
};

export { useConsentModals };
