import styled from 'styled-components';

const StyledH3 = styled.h3`
  font-weight: 600;
  font-size: 24px;
  hyphens: auto;
  word-wrap: break-word;
  margin: 0 10px 0 30px;
  width: inherit;

`;

export default StyledH3;
