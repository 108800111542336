import React, { useContext, useState } from 'react';

interface ProductSummaryProps {
  summaryElements: string[];
  setSummaryElements: React.Dispatch<React.SetStateAction<string[]>>;
}

const ProductSummaryContext = React.createContext<
  ProductSummaryProps | undefined
>(undefined);

export function useProductSummary() {
  const context = useContext(ProductSummaryContext);
  if (context === undefined) {
    throw new Error();
  }
  return context;
}

const ProductSummaryProvider: React.FC = ({ children }) => {
  const [summaryElements, setSummaryElements] = useState<string[]>([]);
  return (
    <ProductSummaryContext.Provider
      value={{
        summaryElements,
        setSummaryElements,
      }}
    >
      {children}
    </ProductSummaryContext.Provider>
  );
};

export default ProductSummaryProvider;
