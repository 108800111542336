import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import StyledSelect from '../../ui/StyledSelect';

const StyledRecommendationLabel = styled.label`
  margin: 25px 0;
  height: 20px;
`;

export interface RecommendationElementProps {
  id: string;
  title: string;
  price: number | undefined;
}

interface RecommendationSelectProps {
  options?: RecommendationElementProps[];
  onSelectionChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  value: string;
}

const DEFAULT_LABEL = 'Unsere Empfehlung für Sie:';

const RecommendationSelect: React.FC<RecommendationSelectProps> = ({
  options,
  onSelectionChange,
  value,
}) => {
  const defaultValue = useRef<string>();

  const [label, setLabel] = useState<string | null>(DEFAULT_LABEL);

  useEffect(() => {
    if (!defaultValue.current && value) defaultValue.current = value;

    if (defaultValue.current === value) {
      return setLabel(DEFAULT_LABEL);
    }
    return setLabel(null);
  }, [value]);

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onSelectionChange(e);
  };

  if (!options) return null;

  return (
    <>
      <StyledRecommendationLabel htmlFor="recommendationSelect">
        {label}
      </StyledRecommendationLabel>
      <StyledSelect
        name="recommendationSelect"
        width="100%"
        maxWidth="250px"
        onChange={handleOnChange}
        value={value}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </StyledSelect>
    </>
  );
};

export default RecommendationSelect;
