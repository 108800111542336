import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

export interface CheckboxInputPropsInterface {
  checked: boolean;
  required: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`;

const HiddenCheckbox = styled.input.attrs<CheckboxInputPropsInterface>({
  type: 'checkbox',
})`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${(props) => props.theme.color.white};
  stroke-width: 2px;
`;

const CustomCheckbox = styled.div<CheckboxInputPropsInterface>`
  display: inline-block;
  width: 22px;
  height: 22px;
  background: ${(props) =>
    props.checked ? props.theme.color.primary : props.theme.color.white};
  border-width: 1.5px;
  border-style: solid;
  border-color: ${(props) =>
    props.checked ? props.theme.color.primary : props.theme.color.black};
  border-radius: 3px;
  transition: all 150ms;
  margin-left: 3px;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${(props) => props.theme.color.primary}50;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }

  ${(props) =>
    props.disabled && `border-color: ${props.theme.color.inputDisabled};`}
`;

const StyledCheckbox: React.FC<CheckboxInputPropsInterface> = ({
  onChange,
  disabled,
  ...rest
}) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox disabled={disabled} {...rest} onChange={onChange} />
      <CustomCheckbox disabled={disabled} {...rest}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </CustomCheckbox>
    </CheckboxContainer>
  );
};

export default StyledCheckbox;
