import React from 'react';
import styled from 'styled-components';
import ProductIcon from '../ProductIcon';
import CardNumber from './Components/CardNumber';
import StyledH3 from '../../../ui/StyledH3';
import { Icon } from '../../../../context/ProductProvider';
import { getContentURL } from '../../../../lib/url-helper';

interface CardHeightProps {
  narrowCard: boolean;
}

const StyledCardContainer = styled.div<CardHeightProps>`
  min-height: ${(props) => (props.narrowCard ? '100px' : '180px')};
  height: ${(props) => (props.narrowCard ? '100px' : '240px')};
  padding: 26px;
  min-width: ${(props) => (props.narrowCard ? '100px' : '380px')};
  border-radius: 14px;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.14);
  background: linear-gradient(to left, #fff, #e4e4e4);
  font-size: 24px;
  font-weight: 600;
  color: #262626;
  display: flex;
  flex-direction: column;
  flex: 0 0 33.333333%;

  @media screen and (max-width: 768px) {
    min-height: inherit;
    flex-grow: 1;
  }
  @media screen and (max-width: 568px) {
    wrap: unwrap;
    min-height: inherit;
    min-width: inherit;
  }
`;

const StyledTopContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBankLogo = styled.img`
  width: 40px;
  margin: 0 0 auto auto;
`;

const StyledBottomContainer = styled.div`
  margin: auto auto 0 0;
  text-align: initial;
`;

interface BottomParagraphProps {
  secondEntry?: boolean;
}

export const StyledBottomParagraph = styled.p<BottomParagraphProps>`
  margin: ${(props) => (props.secondEntry ? '8px 0 2px 0 ' : '0')};
  letter-spacing: 3.08px;
  font-weight: 400;
  font-size: 20px;
`;

interface CardProps {
  icon: Icon;
  title: string;
  numberText?: string;
  digits: string;
  lastFourDigitsOnly?: boolean;
  narrowCard?: boolean;
}

const Card: React.FC<CardProps> = ({
  icon,
  title,
  numberText,
  digits,
  lastFourDigitsOnly,
  narrowCard = false,
}) => {
  if (lastFourDigitsOnly && digits.length >= 4) {
    digits = digits.substring(digits.length - 4);
  }

  return (
    <StyledCardContainer narrowCard={narrowCard}>
      <StyledTopContainer>
        <ProductIcon icon={icon} />
        <StyledH3>{title}</StyledH3>
        <StyledBankLogo src={getContentURL('card_logo.png')} alt="" />
      </StyledTopContainer>
      <StyledBottomContainer>
        {numberText && (
          <StyledBottomParagraph>{numberText}</StyledBottomParagraph>
        )}
        <CardNumber digits={digits} lastFourDigitsOnly={lastFourDigitsOnly} />
      </StyledBottomContainer>
    </StyledCardContainer>
  );
};

export default Card;
