import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { getContentURL } from '../../lib/url-helper';
import { Theme as ThemeType} from './types';



const fallback = {
  color: {
    primary: '#8c8c8c',
    secondary: '#a5ada5',
    tertiary: '#f2f2f2',
    secondaryButton: '#bfbfbf',
    inputBackground: '#f2f2f2',
    white: '#ffffff',
    black: '#4B4237',
    buttonPrimaryHover: '#bfbfbf',
    buttonPrimaryPress: '#bfbfbf',
    inputDisabled: '#8c8c8c',
    buttonSubmit: '#ffffff', 
    buttonSubmitText: '#000000'
  },
  spacing: {
    xs: '4px',
    s: '20px',
    m: '28px',
    l: '72px',
    xl: '100px',
  },
  font: {
    family: 'Source Sans Pro, sans-serif',
    size: {
      normal: '16px',
    },
  },
}

const Theme: React.FC<{}> = ({ children }) => { 
  const [theme, setTheme] = useState<ThemeType>({...fallback});

  useEffect(() => {
      axios.get<ThemeType>(getContentURL('theme.json')).then((response) => {
        setTheme(response.data);
      });
    }, []);
  
  return (
    <ThemeProvider
      theme={theme}
    >
      {children}
    </ThemeProvider>
)};

export default Theme;

