import React from 'react';
import styled from 'styled-components';
import useContent from '../../context/ContentProvider/useContent';
import StyledContent from '../layout/StyledContent/StyledContent';
import Subfooter from './Subfooter';

const StyledLinkSection = styled.section`
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 799px) {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
`;

const StyledLink = styled.a`
  font-size: ${(props) => props.theme.font.size.normal};
  color: ${(props) => props.theme.color.secondaryButton};
  text-decoration: none;

  @media screen and (max-width: 799px) {
    margin: 5px 0 10px 0;
  }
`;

const Footer: React.FC<{}> = () => {
  const { data } = useContent();
  return (
    <footer>
      <Subfooter />
      <StyledContent small>
        <StyledLinkSection>
          {data?.footer?.links?.map(({ text, url }, i) => (
            <StyledLink key={i} href={url} target="_blank">
              {text}
            </StyledLink>
          ))}
        </StyledLinkSection>
      </StyledContent>
    </footer>
  );
};

export default Footer;
