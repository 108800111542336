import React from 'react';
import styled from 'styled-components';
import useContent from '../../../../context/ContentProvider/useContent';
import { useProductSummary } from '../../../../context/ProductProvider/ProductSummaryProvider';
import StyledButton, { ButtonTheme } from '../../../ui/StyledButton';
import StyledIcon from '../../../ui/StyledIcon';
import StyledList from '../../../ui/StyledList';
import StyledModal from '../../../ui/StyledModal';
import { ReactComponent as Check } from '../../assets/check.svg';
import './Modal.css';

const StyledListElement = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 600;
  padding: 10px;
  border-bottom: 1px solid ${(props) => props.theme.color.inputBackground};
  &:first-of-type {
    border-top: 1px solid ${(props) => props.theme.color.inputBackground};
  }
  &:last-of-type {
    border: none;
  }
  div {
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: 25px;
    path {
      stroke: ${(props) => props.theme.color.black};
    }
  }
`;

const SummaryEntry = ({ title }: { title: string }) => (
  <StyledListElement key={title}>
    <div>
      <StyledIcon src={Check} />
      {title}
    </div>
  </StyledListElement>
);

type ModalProps = {
  showModal: boolean;
  onCloseClick: () => void;
  onConfirmClick: () => void;
  selectedTitles: string[];
  loading?: boolean;
};

const Modal = ({
  showModal,
  onCloseClick,
  onConfirmClick,
  selectedTitles,
  loading,
}: ModalProps) => {
  const { data } = useContent();
  const { summaryElements } = useProductSummary();

  const buttons = [];
  if (data?.modal.summaryCloseButton) {
    buttons.push(
      <StyledButton
        key="close"
        onClick={onCloseClick}
        buttonTheme={ButtonTheme.PRIMARY_OUTLINED}
      >
        {data?.modal.summaryCloseButton}
      </StyledButton>,
    );
  }
  if (data?.modal.summaryPrintButton) {
    buttons.push(
      <StyledButton
        key="print"
        onClick={()=> window.print()}
        buttonTheme={ButtonTheme.PRIMARY_OUTLINED}
      >
        {data?.modal.summaryPrintButton}
      </StyledButton>,
    );
  }
  buttons.push(
    <StyledButton key="confirm" onClick={onConfirmClick} loading={loading}>
      {data?.modal.summaryButton}
    </StyledButton>,
  );


  return (
    <StyledModal
      show={showModal}
      id="summary-modal"
      title={data?.modal.summaryTitle}
      details={data?.modal.summaryDetails}
      handleClose={onCloseClick}
      buttons={buttons}
    >
      <StyledList>
        {summaryElements &&
          summaryElements.map((entry) => (
            <SummaryEntry key={entry} title={entry} />
          ))}
        {selectedTitles.map((title, i) => (
          <SummaryEntry key={i} title={title} />
        ))}
      </StyledList>
    </StyledModal>
  );
};

export default Modal;
