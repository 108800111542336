import React from 'react';
import useContent from '../../../context/ContentProvider/useContent';
import { Icon } from '../../../context/ProductProvider';
import DownloadRadioButtonWrapper from '../shared/DownloadRadioButtonWrapper';

export interface DocumentRadioButtonProps {
  description: string;
  infoUrl: string;
  radioButtonText: string;
  icon: Icon;
  title: string;
  id: string;
  onAgreeSelect: (id: string) => void;
  onDisagreeSelect: (id: string) => void;
}

const DocumentRadioButton: React.FC<DocumentRadioButtonProps> = ({
  title,
  id,
  radioButtonText = 'Auswählen',
  icon,
  description,
  infoUrl,
  onAgreeSelect,
  onDisagreeSelect,
}) => {
  const { data } = useContent();

  return (
    <DownloadRadioButtonWrapper
      title={title}
      onAgreeSelect={onAgreeSelect}
      agreeLabel={data?.product.radioButtonAgree}
      disagreeLabel={data?.product.radioButtonDisagree}
      onDisagreeSelect={onDisagreeSelect}
      infoUrl={infoUrl}
      icon={icon}
      description={description}
      id={id}
    >
      <p>{radioButtonText}</p>
    </DownloadRadioButtonWrapper>
  );
};

export default DocumentRadioButton;
