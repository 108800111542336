import { useCallback, useState, useMemo } from 'react';
import { DocumentCheckboxType } from '../../../context/ProductProvider';
import { useGetProducts } from '../../../context/ProductProvider/useProducts';
import { isDocumentCheckboxObject, isObjectRequired, getCheckedList } from '../utils';

const getSelectedDocumentCheckboxObjects = (
  checkboxObjects: DocumentCheckboxType[],
  selectedIds: string[],
) => {
  return selectedIds.reduce<DocumentCheckboxType[]>((prev, selectedId) => {
    const selectedCheckbox = checkboxObjects.find(
      (checkbox) => checkbox.uniqueId === selectedId,
    );
    if (selectedCheckbox) {
      return [...prev, selectedCheckbox];
    }
    return prev;
  }, []);
};

const useDocumentCheckbox = () => {
  const { data } = useGetProducts();
  const [selectedDocumentCheckboxIds, setSelectedDocumentCheckboxIds] = useState<string[]>([]);

  const checkboxObjects = useMemo(
    () => data?.filter<DocumentCheckboxType>(isDocumentCheckboxObject) || [],
    [data],
  );
  const requiredDocumentCheckbox = useMemo(
    () => checkboxObjects?.filter(isObjectRequired) || [],
    [checkboxObjects],
  );
  const optionalDocumentCheckbox = useMemo(
    () =>
      checkboxObjects?.filter((product) => !isObjectRequired(product)) || [],
    [checkboxObjects],
  );

  const onDocumentCheckboxCheck = useCallback(
    (id: string) => {
      setSelectedDocumentCheckboxIds(getCheckedList(selectedDocumentCheckboxIds, id));
    },
    [selectedDocumentCheckboxIds],
  );

  const selectedDocumentCheckboxObjects = getSelectedDocumentCheckboxObjects(
    checkboxObjects,
    selectedDocumentCheckboxIds,
  );

  return {
    requiredDocumentCheckbox,
    optionalDocumentCheckbox,
    onDocumentCheckboxCheck,
    selectedDocumentCheckboxIds,
    selectedDocumentCheckboxObjects,
  };
};

export { useDocumentCheckbox };
