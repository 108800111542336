import {
  ProductData,
  DefaultType,
  CheckboxType,
  DropdownType,
  DocumentType,
  MappedProduct,
  AdvertisementType,
  DocumentCheckboxType,
  DocumentRadioButtonType,
  InputType,
  ProductUpdateType,
  GDPRObjectType,
} from '../../context/ProductProvider/types';

const getCheckedList = (selected: string[], id: string) => {
  const selectedObjectsCopy = [...selected];
  const index = selectedObjectsCopy.indexOf(id);
  if (index > -1) {
    selectedObjectsCopy.splice(index, 1);
  } else {
    selectedObjectsCopy.push(id);
  }
  return selectedObjectsCopy;
};

const getRadioButtonList = (
  selected: string[],
  id: string,
  remove: boolean = false,
) => {
  const selectedObjectsCopy = [...selected];
  const index = selectedObjectsCopy.indexOf(id);
  if (remove && index >= 0) {
    selectedObjectsCopy.splice(index, 1);
  } else if (!remove && index === -1) {
    selectedObjectsCopy.push(id);
  }
  return selectedObjectsCopy;
};

const isAnyProductPresent = (productList: Array<ProductData[]>) => {
  const hasProduct = productList.find(
    (productList) => productList && productList.length > 0,
  );
  return hasProduct !== undefined;
};

const isObjectRequired = (product: ProductData) => {
  return product.consent_object.required;
};

const isCardObject = (
  product: ProductData,
): product is DropdownType | DefaultType => {
  const { type } = product.consent_object;
  if (type === 'DEFAULT' || type === 'SELECT') return true;
  return false;
};

const isDropdownCard = (product: ProductData): product is DropdownType => {
  const { type } = product.consent_object;
  if (type === 'SELECT') return true;
  return false;
};

const isDefaultCard = (product: ProductData): product is DefaultType => {
  const { type } = product.consent_object;
  if (type === 'DEFAULT') return true;
  return false;
};

const isGDPRObject = (product: ProductData): product is GDPRObjectType => {
  const { type } = product.consent_object;
  if (type === 'DSGVO') return true;
  return false;
};

function isCheckboxObject(product: ProductData): product is CheckboxType;
function isCheckboxObject(product: MappedProduct): product is MappedProduct;
function isCheckboxObject(product: MappedProduct | ProductData) {
  const { type } = product.consent_object;
  if (type === 'CHECKBOX') return true;
  return false;
}

function isDocumentCheckboxObject(
  product: ProductData,
): product is DocumentCheckboxType;
function isDocumentCheckboxObject(
  product: MappedProduct,
): product is MappedProduct;
function isDocumentCheckboxObject(product: MappedProduct | ProductData) {
  const { type } = product.consent_object;
  if (type === 'DOCUMENT_CHECKBOX') return true;
  return false;
}

const isDocumentObject = (product: ProductData): product is DocumentType => {
  const { type } = product.consent_object;
  if (type === 'DOCUMENT') return true;
  return false;
};

const isAdvertisementType = (
  product: ProductData,
): product is AdvertisementType => {
  const { type } = product.consent_object;
  if (type === 'ADVERTISEMENT') return true;
  return false;
};

const isInputType = (
  product: MappedProduct | ProductData,
): product is InputType => {
  const { type } = product.consent_object;
  if (type === 'INPUT') return true;
  return false;
};

const isDocumentRadioButtonType = (
  product: MappedProduct | ProductData,
): product is DocumentRadioButtonType => {
  const { type } = product.consent_object;
  if (type === 'DOCUMENT_RADIOBUTTON') return true;
  return false;
};

const isProductUpdateType = (
  product: MappedProduct | ProductData,
): product is ProductUpdateType => {
  const { type } = product.consent_object;
  if (type === 'PRODUCT_UPDATE') return true;
  return false;
};

export {
  isDropdownCard,
  isDefaultCard,
  isCheckboxObject,
  isDocumentCheckboxObject,
  isDocumentRadioButtonType,
  isObjectRequired,
  isDocumentObject,
  isAdvertisementType,
  isInputType,
  isCardObject,
  getCheckedList,
  getRadioButtonList,
  isAnyProductPresent,
  isProductUpdateType,
  isGDPRObject,
};
