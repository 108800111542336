import styled from 'styled-components';
import StyledParagraph from '../../ConsentArea/components/GDPRSection/components/StyledParagraph';
import StyledCheckbox from '../../ui/StyledCheckbox/StyledCheckbox';
import StyledLabel from '../../ui/StyledLabel';
import StyledSummaryDetail from '../../ui/StyledSummaryDetail/StyledSummaryDetail';
import React from 'react';

import Check from '../../../components/ConsentArea/assets/check.svg';

interface GDPRSelectionProps {
  title: string;
  description: string;
  label: string;
  open?: boolean;
  checked?: boolean;
  onChange?: () => void;
  initialOpen?: boolean;
  disabled: boolean;
}
const StyledDetailsWrapper = styled.div`
  padding: 40px;
`;

const StyledLabelWrapper = styled.div`
  display: flex;
  padding: 25px;
  background-color: white;
`;

const StyledImageSpan = styled.span`
  margin-left: 10px;
`;

const StyledMarginlessParagraph = styled(StyledParagraph)`
  margin: 0;
`;

const GDPRSelection = ({
  title,
  description,
  open,
  label,
  checked,
  onChange,
  disabled,
}: GDPRSelectionProps) => {
  return (
    <div>
      <StyledSummaryDetail
        open={open}
        description={
          <>
            <StyledDetailsWrapper>
              <StyledParagraph
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></StyledParagraph>
            </StyledDetailsWrapper>
            <StyledLabelWrapper>
              <StyledLabel disabled={disabled}>
                <StyledCheckbox
                  checked={Boolean(checked)}
                  required={false}
                  onChange={onChange}
                  disabled={disabled}
                />
                {label}
              </StyledLabel>
            </StyledLabelWrapper>
          </>
        }
        title={
          <StyledMarginlessParagraph>
            {title}
            <StyledImageSpan style={{ display: checked ? '' : 'none' }}>
              <img src={Check} />
            </StyledImageSpan>
          </StyledMarginlessParagraph>
        }
      />
    </div>
  );
};

export default GDPRSelection;
