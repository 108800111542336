import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'normalize.css';
import LandingPage from './components/pages/LandingPage';
import RequireLogin from './components/utility/RequireLogin';
import ProductPage from './components/pages/ProductPage';

export enum AppPath {
  ROOT = '/',
  PRODUCTS = 'products',
}

export const routing = (
  <BrowserRouter>
    <Routes>
      <Route path={AppPath.ROOT} element={<App />}>
        <Route index element={<LandingPage />} />
        <Route
          path={AppPath.PRODUCTS}
          element={
            <RequireLogin>
              <ProductPage />
            </RequireLogin>
          }
        />
      </Route>
    </Routes>
  </BrowserRouter>
);

ReactDOM.render(
  <React.StrictMode>{routing}</React.StrictMode>,
  document.getElementById('app'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
