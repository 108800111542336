import React from 'react';
import useContent from '../../../context/ContentProvider/useContent';
import styled from 'styled-components';
import { Icon } from '../../../context/ProductProvider';
import DownloadRadioButtonWrapper from '../shared/DownloadRadioButtonWrapper';
import Card from '../shared/Card';

export interface ProductUpdateRadioButtonProps {
  description: string;
  infoUrl: string;
  icon: Icon;
  title: string;
  id: string;
  accountNumber: string;
  firstProductTitle?: string;
  secondProductTitle?: string;
  onAgreeSelect: (id: string) => void;
  onDisagreeSelect: (id: string) => void;
}

const StyledProductUpdateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const StyledArrow = styled.div`
  min-height: 30px;
  min-width: 30px;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  transform: rotate(-45deg);
  border-color: ${(props) => props.theme.color.primary};
  margin: 0 30px 0 15px;
  box-sizing: border-box;
  @media screen and (max-width: 600px) {
    transform: rotate(45deg);
    margin: 15px 0 30px 0;
  }
`;

const ProductUpdateRadioButton: React.FC<ProductUpdateRadioButtonProps> = ({
  title,
  id,
  icon,
  description,
  infoUrl,
  accountNumber,
  firstProductTitle = '',
  secondProductTitle = '',
  onAgreeSelect,
  onDisagreeSelect,
}) => {
  const { data } = useContent();

  return (
    <DownloadRadioButtonWrapper
      title={title}
      onAgreeSelect={onAgreeSelect}
      agreeLabel={data?.product.radioButtonAgree}
      disagreeLabel={data?.product.radioButtonDisagree}
      onDisagreeSelect={onDisagreeSelect}
      infoUrl={infoUrl}
      icon={'SWITCH'}
      description={description}
      id={id}
    >
      <StyledProductUpdateWrapper>
        <Card
          icon={icon}
          title={firstProductTitle}
          digits={accountNumber}
          narrowCard
        />
        <StyledArrow />
        <Card
          icon={icon}
          title={secondProductTitle}
          digits={accountNumber}
          narrowCard
        />
      </StyledProductUpdateWrapper>
    </DownloadRadioButtonWrapper>
  );
};

export default ProductUpdateRadioButton;
