import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export enum ButtonTheme {
  PRIMARY,
  SECONDARY,
  PRIMARY_OUTLINED,
  SUBMIT
}

interface StyledButtonProps {
  buttonTheme?: ButtonTheme;
}

interface CustomButtonProps
  extends StyledButtonProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  font-size: 16px;
  font-weight: bold;
  padding: 15px 50px;
  cursor: pointer;
  background-color: ${(props) => {
    if (props.buttonTheme === ButtonTheme.PRIMARY) {
      // primary button != primary color
      return props.theme.color.secondary;
    } else if (props.buttonTheme === ButtonTheme.SECONDARY) {
      return props.theme.color.primary;
    } else if (props.buttonTheme === ButtonTheme.SUBMIT) {
      return props.theme.color.buttonSubmit;
    } else {
      // for all outlined styles
      return props.theme.color.white;
    }
  }};
  color: ${(props) => {
    if (props.buttonTheme === ButtonTheme.PRIMARY_OUTLINED) {
      return props.theme.color.secondary;
    } 
    else if (props.buttonTheme === ButtonTheme.SUBMIT) {
    return props.theme.color.buttonSubmitText;
    } 
    else {
      return props.theme.color.white;
    }
  }};
  border-radius: 7px;
  border-style: solid;
  border-width: 1.5px;
  border-color: ${(props) => {
    if (
      props.buttonTheme === ButtonTheme.PRIMARY ||
      props.buttonTheme === ButtonTheme.PRIMARY_OUTLINED
    ) {
      return props.theme.color.secondary;
    } else if (props.buttonTheme === ButtonTheme.SECONDARY) {
      return props.theme.color.white;
    } else if (props.buttonTheme === ButtonTheme.SUBMIT) {
      return props.theme.color.buttonSubmit; 
    }
  }};
  &:hover {
    ${(props) => {
      switch (props.buttonTheme) {
        case ButtonTheme.PRIMARY:
          return `background-color: ${props.theme.color.buttonPrimaryHover};`;
        default:
      }
    }}
    &:active {
      ${(props) => {
        switch (props.buttonTheme) {
          case ButtonTheme.PRIMARY:
            return `background-color: ${props.theme.color.buttonPrimaryPress};`;
          default:
        }
      }}
    }
  }
  & + & {
    margin-left: 20px;
  }
  &:disabled {
    background-color: ${(props) => props.theme.color.inputDisabled};
    border-color: ${(props) => props.theme.color.white};
    cursor: not-allowed;
  }
`;

const CustomButton: React.FC<CustomButtonProps> = ({
  loading,
  children,
  ...rest
}) => {
  return (
    <StyledButton {...rest}>
      {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : children}
    </StyledButton>
  );
};

CustomButton.defaultProps = {
  buttonTheme: ButtonTheme.PRIMARY,
  loading: false,
};

export default CustomButton;
