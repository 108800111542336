import React from 'react';
import PreselectedCard from '../../../products/PreselectedCard';
import DropdownCard from '../../../products/DropdownCard';
import {
  DefaultType,
  DropdownType,
  ProductData,
} from '../../../../context/ProductProvider';
import {
  DropdownSelection,
  ChangeDropdownSelection,
} from '../../hooks/useCards';
import StyledList from '../../../ui/StyledList';
import { isDefaultCard, isDropdownCard } from '../../utils';

type ConsentObjectOptions = ProductData['consent_object']['options'][0];

const mapToDropdownOptions = ({ id, title, price }: ConsentObjectOptions) => ({
  id,
  title,
  price,
});

const mapProductToProps = (product: DefaultType | DropdownType) => {
  return {
    title: product.consent_object.title,
    numberText: product.consent_object.description,
    icon: product.consent_object.icon_type,
    price: product.consent_object.price,
    bonus: product.bonus,
    digits: product.account_number,
    lastFourDigitsOnly: product.account_number.length <= 4,
    priceInterval: product.consent_object.price_interval,
    informationUrl: product.consent_object.info_url,
    key: product.uniqueId,
  };
};

type CardSectionProps = {
  dropdownSelections: DropdownSelection;
  changeDropdownSelection: ChangeDropdownSelection;
  data: (DefaultType | DropdownType)[];
};

const CardSection = ({
  data,
  dropdownSelections,
  changeDropdownSelection,
}: CardSectionProps) => {
  return (
    <StyledList>
      {data.map((product) => {
        if (isDefaultCard(product)) {
          return <PreselectedCard {...mapProductToProps(product)} />;
        }

        if (isDropdownCard(product)) {
          return (
            <DropdownCard
              {...mapProductToProps(product)}
              id={product.uniqueId}
              selected={dropdownSelections[product.uniqueId]}
              options={product.consent_object.options.map(mapToDropdownOptions)}
              changeDropdownSelection={changeDropdownSelection}
            />
          );
        }

        return null;
      })}
    </StyledList>
  );
};

export default CardSection;
