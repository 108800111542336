import { AxiosResponse } from 'axios';
import {
  useQuery,
  useMutation,
  UseMutationOptions,
} from '@tanstack/react-query';
import { FetchedProductData, MappedProduct } from '.';
import { addUniqueId } from './utils';
import apiClient from '../../api/apiClient';
import { useProductSummary } from './ProductSummaryProvider';

const getProducts = async (type?: string) => {
  const { data } = await apiClient.get<FetchedProductData[]>(
    '/customer_consent_objects' + (type ? `?type=${type}` : ''),
  );
  return addUniqueId(data);
};

export function useGetProducts(type?: string) {
  const { setSummaryElements } = useProductSummary();
  return useQuery(['products', type], () => getProducts(type), {
    onSuccess: (data) => {
      if (type === 'documents') {
        if (data.length > 0) {
          setSummaryElements(
            data.map((product) => {
              return product.consent_object.title;
            }),
          );
        }
      } else {
        const alreadyConfirmed = data?.find(
          (data) => data.consent_object.type === 'CONFIRMATION',
        );
        if (!alreadyConfirmed) {
          setSummaryElements([]);
        }
      }
    },
  });
}

const updateProducts = async (products: MappedProduct[]) => {
  return await apiClient.put('customer_consent_objects', products, {
    method: 'PUT',
  });
};

export function useUpdateProducts(
  options?:
    | Omit<
        UseMutationOptions<
          AxiosResponse<any, any>,
          unknown,
          MappedProduct[],
          unknown
        >,
        'mutationFn'
      >
    | undefined,
) {
  return useMutation(updateProducts, {
    ...options,
  });
}
