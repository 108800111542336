import {
  ProductData,
  MappedProduct,
  FetchedProductData,
  DocumentType,
} from './';
import { DropdownSelectionObject } from '../../components/ConsentArea/hooks/useCards';
import {
  isCheckboxObject,
  isDocumentCheckboxObject,
  isDocumentRadioButtonType,
  isGDPRObject,
  isInputType,
  isProductUpdateType,
} from '../../components/ConsentArea/utils';

const mapRequiredToStatus = (productCopy: MappedProduct) => {
  const isRequired = productCopy.consent_object.required;
  productCopy.status = isRequired ? 1 : -1;
  return productCopy;
};

const mapInputValues =
  (selectedIds: string[]) => (productCopy: MappedProduct) => {
    if (isInputType(productCopy)) {
      const elem = document.getElementById(
        productCopy.uniqueId,
      ) as HTMLInputElement;
      productCopy.custom_data = elem.value;
      if (!productCopy.consent_object.required) {
        const isCheckboxSelected = selectedIds.includes(productCopy.uniqueId!);
        if (productCopy.consent_object.required) {
          productCopy.status = isCheckboxSelected ? 1 : -1;
        } else {
          productCopy.status = isCheckboxSelected ? 1 : 0;
        }
      }
    }
    return productCopy;
  };

const mapSelectedCheckboxToStatus =
  (selectedIds: string[]) => (productCopy: MappedProduct) => {
    if (
      isCheckboxObject(productCopy) ||
      isDocumentCheckboxObject(productCopy) ||
      isDocumentRadioButtonType(productCopy) ||
      isProductUpdateType(productCopy) ||
      isGDPRObject(productCopy)
    ) {
      const isCheckboxSelected = selectedIds.includes(productCopy.uniqueId!);
      if (productCopy.consent_object.required) {
        productCopy.status = isCheckboxSelected ? 1 : -1;
      } else {
        productCopy.status = isCheckboxSelected ? 1 : 0;
      }
    }
    return productCopy;
  };

const mapSelectedDropdownProduct =
  (selectedDropdownOptionsObject: DropdownSelectionObject) =>
  (productCopy: MappedProduct) => {
    productCopy.consent_object_option =
      selectedDropdownOptionsObject[productCopy.uniqueId!];
    return productCopy;
  };

const removeUniqueId = (productCopy: MappedProduct) => {
  delete productCopy.uniqueId;
  return productCopy;
};

const addUniqueId = (data?: FetchedProductData[]) => {
  if (!data) return [] as ProductData[];
  return data.map((product, i) => ({
    ...product,
    uniqueId: `${i}`,
  })) as ProductData[];
};

const getMappedProducts = (
  data: ProductData[],
  selectedIds: string[],
  selectedDropdownOptionsObject: DropdownSelectionObject,
) => {
  const dataCopy: MappedProduct[] = JSON.parse(JSON.stringify(data));
  return dataCopy
    .map(mapRequiredToStatus)
    .map(mapSelectedCheckboxToStatus(selectedIds))
    .map(mapSelectedDropdownProduct(selectedDropdownOptionsObject))
    .map(mapInputValues(selectedIds))
    .map(removeUniqueId);
};

const getMappedDocument = (document: DocumentType) => {
  const dataCopy: MappedProduct = JSON.parse(JSON.stringify(document));
  return removeUniqueId(mapRequiredToStatus(dataCopy));
};

export { getMappedProducts, addUniqueId, getMappedDocument };
