import React, { HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';
import StyledH3 from '../../ui/StyledH3';

import {
  CheckboxPropsInterface,
  StyledOptionalConsentObjectContainer,
} from '../Checkbox';
import ProductIcon from '../shared/ProductIcon';
import useContent from '../../../context/ContentProvider/useContent';
import StyledTextInput from './Components/StyledTextInput/StyledTextInput';
import StyledLabel from '../../ui/StyledLabel';
import StyledCheckbox from '../../ui/StyledCheckbox';

const StyledTextInputContainer = styled(StyledOptionalConsentObjectContainer)`
  flex-direction: column;
`;

const StyledRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 799px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const StyledSelectionArea = styled(StyledRowContainer)`
  padding: 20px 0px;
`;

const StyledCheckboxArea = styled(StyledSelectionArea)`
  border-top: 1px solid ${(props) => props.theme.color.inputBackground};
  padding-bottom: 0;
`;

const StyledIconHeaderArea = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export interface TextInputProps
  extends Omit<CheckboxPropsInterface, 'informationText'> {
  description: string;
  initialValue: string;
  type?: HTMLInputTypeAttribute;
}

const TextInput: React.FC<TextInputProps> = ({
  title,
  id,
  labelText = 'Auswählen',
  icon,
  description,
  required,
  isChecked,
  initialValue,
  onCheckboxCheck,
  type,
}) => {
  const { data } = useContent();
  return (
    <StyledTextInputContainer>
      <StyledIconHeaderArea>
        <ProductIcon icon={icon} />
        <StyledH3>{title}</StyledH3>
      </StyledIconHeaderArea>
      <StyledSelectionArea>
        <p dangerouslySetInnerHTML={{ __html: description || '' }}/>
        <StyledTextInput
          id={id}
          placeholder="Bitte ausfüllen"
          defaultValue={initialValue}
          required={required || isChecked}
          type={type}
        />
      </StyledSelectionArea>
      {!required && (
        <StyledCheckboxArea>
          <StyledLabel>
            <StyledCheckbox
              checked={isChecked}
              required={false}
              onChange={() => onCheckboxCheck(id)}
            />
            {data?.product.textInputCheckboxText || labelText}
          </StyledLabel>
        </StyledCheckboxArea>
      )}
    </StyledTextInputContainer>
  );
};

export default TextInput;
