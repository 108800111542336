import React from 'react';
import styled, { css } from 'styled-components';
import StyledH2 from '../StyledH2';

export const Overlay = styled.div<{ show: boolean }>`
  z-index: 1;
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.theme.color.white};
  min-width: 60%;
  max-width: 950px;
  height: auto;
  border-radius: 14px;
  background-color: ${(props) => props.theme.color.white};
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.25);
  padding: 48px;

  @media screen and (max-width: 568px) {
    padding: 28px;
  }
`;

const ModalBody = styled.section`
  padding: 0;
  box-sizing: border-box;
  max-height: 80vh;
  overflow: auto;
`;

interface FooterProps {
  numberOfButtons: number;
}

const ModalFooter = styled.section<FooterProps>`
  display: flex;
  justify-content: flex-end;

  ${({ numberOfButtons }) =>
  numberOfButtons > 1 &&
  css`
  justify-content: space-between;
  `}
`;

export interface ModalProps {
  show: boolean;
  title?: string;
  details?: string;
  buttons?: JSX.Element[];
  checkboxText?: string | undefined;
  infoUrl?: string;
  loading?: boolean;
  id?: string;
  handleClose: () => void;
  handleContinueClick?: () => void;
}

const StyledModal: React.FC<ModalProps> = ({
  show,
  title,
  id,
  details,
  handleClose,
  buttons,
  children,
}) => {
  return (
    <Overlay show={show} onClick={handleClose} id={id}>
      <ModalContainer  onClick={(e) => e.stopPropagation()}>
        <ModalBody>
          {title && <StyledH2>{title}</StyledH2>}
          <p dangerouslySetInnerHTML={{ __html: details || '' }} />
          {children}
        </ModalBody>
        {buttons && <ModalFooter numberOfButtons={buttons.length}>{buttons}</ModalFooter>}
      </ModalContainer>
    </Overlay>
  );
};

export default StyledModal;

