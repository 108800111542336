import React, { HTMLInputTypeAttribute, useState } from 'react';
import styled from 'styled-components';
import deleteIcon from './assets/deleteIcon.svg';
const StyledInput = styled.input`
  border: none;
  background-color: ${(props) =>
    props.color || props.theme.color.inputBackground};
  padding: 1rem 2rem;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;

  ::-webkit-calendar-picker-indicator {
    margin-right: 10px;
  }
`;

const StyledIcon = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 15px;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  font-size: 16px;
`;

interface StyledTextInputProps {
  id: string;
  placeholder: string;
  defaultValue?: string;
  required: boolean;
  type?: HTMLInputTypeAttribute;
}

const StyledTextInput: React.FC<StyledTextInputProps> = ({
  placeholder,
  defaultValue,
  required,
  type,
  id,
}) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <InputContainer>
      <StyledInput
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(() => e.target.value)}
        required={required}
      />
      <StyledIcon
        src={deleteIcon}
        alt="Delete icon"
        onClick={() => setValue(() => '')}
      />
    </InputContainer>
  );
};

export default StyledTextInput;
