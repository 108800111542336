import React from 'react';
import { StyledBottomParagraph } from '../..';

interface CardNumberProps {
  digits: string;
  lastFourDigitsOnly?: boolean;
}

const CardNumber: React.FC<CardNumberProps> = ({
  digits,
  lastFourDigitsOnly,
}) => (
  <StyledBottomParagraph secondEntry>
    {lastFourDigitsOnly ? `•••• •••• •••• ${digits}` : digits}
  </StyledBottomParagraph>
);

export default CardNumber;
