import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { useAuth } from '../../context/AuthProvider';
import { getContentURL } from '../../lib/url-helper';
import StyledContent from '../layout/StyledContent';

const StyledHeader = styled.header`
  img {
    display: flex;
    cursor: pointer;
    @media screen and (max-width: 799px) {
      height: 100%;
      width: 90vw;
      max-width: 90vw;
    }
  }
`;

const Header: React.FC<{}> = () => {
  const { doLogout } = useAuth();
  const queryClient = useQueryClient();
  const handleLogoClick = () => {
    queryClient.clear();
    doLogout();
  };
  return (
    <StyledHeader>
      <StyledContent small>
        <img
          src={getContentURL('logo.png')}
          height="50px"
          onClick={handleLogoClick}
        />
      </StyledContent>
    </StyledHeader>
  );
};

export default Header;
