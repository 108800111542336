import React, { useContext, useState } from 'react';
import { User } from './types';
import { toast } from 'react-toastify';
import useLogin from './useLogin';

interface Auth {
  loggedIn: boolean;
  loading?: boolean;
  error?: Error;
  doLogin: (user: User) => void;
  doLogout: () => void;
}

const AuthContext = React.createContext<Auth | undefined>(undefined);

export function useAuth() {
  const authContext = useContext(AuthContext);
  if (authContext === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return authContext;
}

const AuthProvider: React.FC = ({ children }) => {
  const [isCurrentyLoggedIn, setIsCurrentyLoggedIn] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);

  const doLogout = () => {
    sessionStorage.removeItem('token');
    setIsCurrentyLoggedIn(false);
  };

  const { isLoading, mutate: login } = useLogin({
    onMutate: () => {
      doLogout();
      setIsPending(true);
    },
    onSuccess: (res) => {
      const token = res.headers['x-csrftoken'];
      if (token) {
        sessionStorage.setItem('token', token);
        setIsCurrentyLoggedIn(true);
        setIsPending(false);
      }
    },
    onError: (err) => {
      const error = err as Error;
      if (error.name === 'Info') {
        toast.success(error.message);
      } else {
        toast.error(error.message);
      }
      setIsPending(false);
    },
  });

  return (
    <AuthContext.Provider
      value={{
        loading: isLoading || isPending,
        loggedIn: isCurrentyLoggedIn,
        doLogin: login,
        doLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
