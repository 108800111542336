import React from 'react';
import { getFileEndingFromUrl } from '../../../../lib/getFileEndingFromUrl';
import { StyledLink } from './Components/StyledLink';
import AdditionalInformationModal from './Components/AdditionalInformationModal';
import useContent from '../../../../context/ContentProvider/useContent';

export interface AdditionalInformationComponentProps {
  informationUrl: string;
}

const AdditionalInformationComponent = ({
  informationUrl,
}: AdditionalInformationComponentProps) => {
  const urlIsImage = ['PNG', 'JPG', 'JPEG'].includes(
    getFileEndingFromUrl(informationUrl),
  );
  const { data } = useContent();

  return (
    <>
      {urlIsImage ? (
        <AdditionalInformationModal
          imgUrl={informationUrl}
          moreText={data!.product.moreInfo || 'Mehr Informationen'}
        />
      ) : (
        <StyledLink href={informationUrl} target="_blank">
          {data!.product.moreInfo || 'Mehr Informationen'}
        </StyledLink>
      )}
    </>
  );
};

export default AdditionalInformationComponent;
