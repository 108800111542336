import React from 'react';
import styled from 'styled-components';
import StyledCheckbox from '../../ui/StyledCheckbox';
import { InformationText } from './Components/InformationText/InformationText';
import ProductIcon from '../shared/ProductIcon';
import StyledH3 from '../../ui/StyledH3';
import { Icon } from '../../../context/ProductProvider';

export interface CheckboxPropsInterface {
  title: string;
  informationText?: string;
  labelText?: string;
  icon: Icon;
  onCheckboxCheck: (id: string) => void;
  isChecked: boolean;
  id: string;
  required: boolean;
}

export const StyledOptionalConsentObjectContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 25px ${(props) => props.theme.spacing.s} 25px 25px;
  border-radius: 14px;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.14);
  background-color: ${(props) => props.theme.color.white};
  margin: 25px 0;

  @media screen and (max-width: 799px) {
    flex-direction: column;
  }
`;

export const StyledSelectionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSelectionText = styled.label`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => props.theme.color.secondary};
  user-select: none;
`;

const StyledCheckboxTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: space-around;
  @media screen and (max-width: 799px) {
    width: 100%;
  }
`;

const StyledCheckboxHeader = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
`;

const StyledInformationText = styled.div`
  width: 100%;
`;

const Line = styled.hr`
margin: 20px 0px;
border: 1px solid;
border-color: ${(props) => props.theme.color.inputBackground};
`

const Checkbox: React.FC<CheckboxPropsInterface> = ({
  title,
  labelText = 'Auswählen',
  icon,
  informationText,
  onCheckboxCheck,
  isChecked,
  id,
  required,
}) => {
  const handleCheck = () => {
    onCheckboxCheck(id);
  };
  return (
    <StyledOptionalConsentObjectContainer>
      <StyledCheckboxTitleWrapper>
        <StyledCheckboxHeader>
          <ProductIcon icon={icon} />
          <StyledH3>{title}</StyledH3>
        </StyledCheckboxHeader>
        <StyledInformationText>
          {informationText && (
            <InformationText informationText={informationText} />
          )}
          <Line />
        </StyledInformationText>
        <StyledSelectionContainer>
          <StyledSelectionText>
            <StyledCheckbox
              required={required}
              checked={isChecked}
              onChange={handleCheck}
            />
            {labelText}
          </StyledSelectionText>
        </StyledSelectionContainer>
      </StyledCheckboxTitleWrapper>

    </StyledOptionalConsentObjectContainer>
  );
};

export default Checkbox;
