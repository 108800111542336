import React from 'react';
import styled from 'styled-components';
import CustomButton, { ButtonTheme } from '../../ui/StyledButton';
import useContent from '../../../context/ContentProvider/useContent';

const StyledButtonSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px 30px 40px;
  border: 1px solid ${(props) => props.theme.color.tertiary};
  border-radius: 14px;
  background-color: ${(props) => props.theme.color.primary};
  margin-top: 50px;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    padding: 20px 10px 20px 30px;
    align-items: flex-start;
    gap: 15px;
  }
`;

const StyledSelectionText = styled.p`
  font-size: 32px;
  margin: 0;
  color: ${(props) => props.theme.color.white};
`;

const StyledButtonWrapper = styled.div`
  & :not(:last-child) {
    margin-right: 1em;
  }
`;

const ConsentSelection: React.FC<{ onConfirmClick: () => void }> = ({
  onConfirmClick,
}) => {
  const { data } = useContent();
  return (
    <>
      <StyledButtonSection>
        <StyledSelectionText>
          {data?.product?.yourSelection}
        </StyledSelectionText>
        <StyledButtonWrapper>
          {data?.product?.rejectSelection && (
            <CustomButton buttonTheme={ButtonTheme.SECONDARY}>
              {data?.product?.rejectSelection}
            </CustomButton>
          )}
          <CustomButton onClick={onConfirmClick} buttonTheme={ButtonTheme.SUBMIT}>
            {data?.product?.acceptSelection}
          </CustomButton>
        </StyledButtonWrapper>
      </StyledButtonSection>
    </>
  );
};

export default ConsentSelection;
