import React from 'react';
import styled from 'styled-components';
import StyledContent from '../layout/StyledContent';
import useContent from '../../context/ContentProvider/useContent';
import { getContentURL } from '../../lib/url-helper';

const StyledHeroSection = styled.section`
  height: 100%;
  position: relative;

  @media screen and (max-width: 799px) {
    margin-bottom: 0;
  }
`;

const StyledBlendBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.3;

  @media screen and (max-width: 799px) {
    display: none;
  }
`;

const StyledHeading = styled.h1`
  max-width: 565px;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.08;
  hyphens: auto;
  color: ${(props) => props.theme.color.white};
  margin-bottom: 55px;

  @media screen and (max-width: 799px) {
    font-size: 24px;
    margin-bottom: 25px;
  }
`;

const StyledText = styled.p`
  max-width: 628px;
  line-height: 1.5;
  font-size: ${(props) => props.theme.font.size.normal};
  color: ${(props) => props.theme.color.white};
  a {
    text-decoration: underline;
  }
`;

const StyledGradientBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${(props) => `linear-gradient(
    90deg,
    ${props.theme.color.primary},
    ${props.theme.color.primary} 35%,
    ${props.theme.color.primary}35 51%,
    ${props.theme.color.primary}00
  )`};

  @media screen and (max-width: 799px) {
    background: ${(props) => props.theme.color.primary};
  }
`;

const StyledImage = styled.img`
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: none;
  object-fit: cover;

  @media screen and (max-width: 799px) {
    display: none;
  }
`;

const StyledHeroContent = styled(StyledContent)`
  padding: 100px 60px;
  box-sizing: border-box;

  @media screen and (max-width: 799px) {
    padding: 40px 15px;
  }
`;

const StyledCollapsedHeroSection = styled.section`
  background-color: ${(props) => props.theme.color.primary};
`;

const StyledCollapsedHeading = styled.h1`
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.44;
  color: ${(props) => props.theme.color.white};
`;

interface HeroProps {
  collapsed?: boolean;
}

const Hero: React.FC<HeroProps> = ({ collapsed }) => {
  const { data } = useContent();

  return collapsed ? (
    <StyledCollapsedHeroSection>
      <StyledContent>
        <StyledCollapsedHeading
          dangerouslySetInnerHTML={{
            __html: data?.landingPage?.hero?.heading || '',
          }}
        />
      </StyledContent>
    </StyledCollapsedHeroSection>
  ) : (
    <StyledHeroSection>
      <StyledGradientBlock>
        <StyledBlendBlock />
        <StyledImage src={getContentURL('hero.png')} />
      </StyledGradientBlock>
      <StyledHeroContent>
        <StyledHeading
          dangerouslySetInnerHTML={{
            __html: data?.landingPage?.hero?.heading || '',
          }}
        />
        <StyledText
          dangerouslySetInnerHTML={{
            __html: data?.landingPage?.hero?.paragraph || '',
          }}
        />
      </StyledHeroContent>
    </StyledHeroSection>
  );
};

export default Hero;
