import React from 'react';
import NumberFormat from '../../../../lib/NumberFormat';
import styled from 'styled-components';
import {
  PriceIntervals,
  PriceIntervalsEnum,
} from '../../../../context/ProductProvider';
import Bonus from '../Bonus';

const StyledPriceAreaContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

const StyledPriceParagraph = styled.p`
  margin: 0 0 10px 0;
`;

const PriceText = styled.p`
  color: ${(props) => props.theme.color.secondary};
  font-size: 32px;
  font-weight: 700;
  margin: 10px 0 0 0;
`;

interface PriceAreaProps {
  text?: string;
  price?: number;
  priceInterval?: PriceIntervals;
  prefix?: string;
  bonus?: number;
}

const PriceArea: React.FC<PriceAreaProps> = ({
  text = 'Ihr Grundpreis',
  price = 0,
  priceInterval = 'MONTHLY',
  prefix = 'Nur',
  bonus,
}) => {
  return (
    <StyledPriceAreaContainer>
      <StyledPriceParagraph>{text}</StyledPriceParagraph>
      <PriceText>
        <NumberFormat price={price} prefix={`${prefix} `} />
      </PriceText>
      <StyledPriceParagraph>
        {PriceIntervalsEnum[priceInterval]}
      </StyledPriceParagraph>
      {(bonus || bonus === 0) && <Bonus bonus={bonus} />}
    </StyledPriceAreaContainer>
  );
};

export default PriceArea;
