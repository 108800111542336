import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import apiClient from '../../api/apiClient';
import { User } from './types';

const postLogin = async (user: User) => {
  const url = new URL(location.href).searchParams.get('channel');
  const credentials = { ...user, channel: url != null ? url : undefined };
  return await apiClient.post('/auth/login', credentials);
};

export default function useLogin(
  options?:
    | Omit<
        UseMutationOptions<AxiosResponse<User, any>, unknown, User, unknown>,
        'mutationFn'
      >
    | undefined,
) {
  return useMutation(postLogin, { ...options });
}
