import React from 'react';
import StyledList from '../../../ui/StyledList';
import Checkbox from '../../../products/Checkbox';
import { CheckboxType } from '../../../../context/ProductProvider';

type CheckboxSectionProps = {
  checkboxObjects: CheckboxType[];
  onCheckboxCheck: (type: string) => void;
  selectedIds: string[];
};

const CheckboxSection = ({
  checkboxObjects,
  onCheckboxCheck,
  selectedIds,
}: CheckboxSectionProps) => {
  const isCheckboxChecked = (id: string) => {
    return selectedIds.includes(id);
  };

  return (
    <StyledList>
      {checkboxObjects.map((product) => {
        const { title, description, icon_type, required } =
          product.consent_object;
        return (
          <Checkbox
            title={title}
            informationText={description}
            icon={icon_type}
            id={product.uniqueId}
            required={required}
            key={product.uniqueId}
            onCheckboxCheck={onCheckboxCheck}
            isChecked={isCheckboxChecked(product.uniqueId)}
          />
        );
      })}
    </StyledList>
  );
};

export default CheckboxSection;
