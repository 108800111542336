import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../context/AuthProvider';
import { User } from '../../../context/AuthProvider/types';
import Documents from '../../Documents';
import Footer from '../../Footer';
import Header from '../../Header';
import Hero from '../../Hero';
import Login from '../../Login';
import SpinnerOverlay from '../../ui/SpinnerOverlay';
import { useQueryClient } from '@tanstack/react-query';

const LandingPage: React.FC<{}> = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const { loggedIn, doLogin, loading } = useAuth();
  const id = searchParams.get('id');
  let code = searchParams.get('code');

  // fix for voba albs 'zero in qr code' accident
  const url = window.location.href;
  const isVobaAlb: boolean = url.includes('voba-alb');
  if (code && isVobaAlb) {
    if (code[0] === '0') {
      code = code.substring(1);
    }
  }

  const autoLogin = id && code;

  useEffect(() => {
    queryClient.invalidateQueries(['products']);
  }, [queryClient]);

  useEffect(() => {
    if (autoLogin) {
      const user: User = {
        username: id,
        password: code as string,
      };
      // doLogin also checks for channel parameter, so it will not be done here
      doLogin(user);
    }
  }, [autoLogin]);

  return (
    <>
      <Header />
      <main>
        <Hero />
        <Login />
        {loggedIn && <Documents />}
      </main>
      <Footer />
      <SpinnerOverlay show={!!(loading && autoLogin)} />
    </>
  );
};

export default LandingPage;
