import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppPath } from '../../..';
import { useAuth } from '../../../context/AuthProvider';

const RequireLogin = ({ children }: { children: JSX.Element }) => {
  const { loggedIn } = useAuth();
  const location = useLocation();
  if (!loggedIn) {
    /* Store the path the user was trying to access in state object, 
    this may be useful once there are more pages than ProductPage */
    return <Navigate to={AppPath.ROOT} state={{ from: location }} replace />;
  }
  return children;
};

export default RequireLogin;
