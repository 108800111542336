import React, { useState } from 'react';
import styled from 'styled-components';
import StyledButton from '../../ui/StyledButton';
import StyledCheckbox from '../../ui/StyledCheckbox';
import StyledModal, { ModalProps } from '../../ui/StyledModal';
import { saveAs } from 'file-saver';
import useContent from '../../../context/ContentProvider/useContent';

const StyledLabel = styled.label<{ disabled: boolean }>`
  display: flex;
  margin: 20px 0 50px 0;
  ${(props) => props.disabled && `color: ${props.theme.color.inputDisabled};`}
`;

const StyledHorizontalLine = styled.hr`
  width: calc(100% - 2px);
  border: 1px solid;
  border-color: ${(props) => props.theme.color.inputBackground};
`;

const DocumentModal: React.FC<ModalProps> = ({
  handleContinueClick,
  checkboxText,
  infoUrl,
  loading,
  ...rest
}) => {
  const [checkboxEnabled, setCheckboxEnabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const {data} = useContent();

  const handleDownloadClick = () => {
    if (infoUrl) {
      const isMobileOrFirefox = /iPhone|iPad|iPod|webOS|Android|Firefox/i.test(
        navigator.userAgent,
      );
      if (isMobileOrFirefox) {
        window.open(infoUrl);
      } else {
        const fileName = infoUrl.split('/').pop();
        saveAs(infoUrl, fileName);
      }
    }
    setCheckboxEnabled(true);
  };

  return (
    <StyledModal
      {...rest}
      buttons={[
        <StyledButton
          key="continue"
          onClick={handleContinueClick}
          disabled={!checked}
          loading={loading}
        >
          {data?.modal.documentContinueText}
        </StyledButton>,
      ]}
    >
      <StyledButton key="download" onClick={handleDownloadClick}>
        Herunterladen
      </StyledButton>
      <StyledHorizontalLine />
      <StyledLabel disabled={!checkboxEnabled}>
        <StyledCheckbox
          required
          checked={checked}
          disabled={!checkboxEnabled}
          onChange={(e) => setChecked(e.target.checked)}
        />
        {checkboxText}
      </StyledLabel>
    </StyledModal>
  );
};

export default DocumentModal;
