import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from '../../../../context/ProductProvider';
import StyledRadioButton from '../../../ui/StyledRadioButton';
import DownloadDocumentWrapper from '../DownloadDocumentWrapper';

export interface DownloadRadioButtonWrapperProps {
  description: string;
  infoUrl: string;
  icon: Icon;
  title: string;
  id: string;
  onAgreeSelect: (id: string) => void;
  onDisagreeSelect: (id: string) => void;
  agreeLabel?: string;
  disagreeLabel?: string;
}

const StyledRadioButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 10px;

  @media screen and (max-width: 490px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: center;
    gap: 0;
  }
`;

const DownloadRadioButtonWrapper: React.FC<DownloadRadioButtonWrapperProps> = ({
  title,
  id,
  icon,
  description,
  infoUrl,
  onAgreeSelect,
  agreeLabel,
  onDisagreeSelect,
  disagreeLabel,
  children,
}) => {
  const [isDownloaded, setIsDownloaded] = useState(false);
  const handleLabelClick = (
    event: React.MouseEvent<HTMLLabelElement>,
    agree: boolean,
  ) => {
    const element = event.target as HTMLInputElement;
    const inputId = element.getAttribute('for');

    if (inputId) {
      if (agree) {
        onAgreeSelect(id);
      } else {
        onDisagreeSelect(id);
      }
    }
  };
  return (
    <DownloadDocumentWrapper
      infoUrl={infoUrl}
      icon={icon}
      title={title}
      description={description}
      onDownloaded={() => setIsDownloaded(true)}
      required={true}
    >
      <>
        {children}
        <StyledRadioButtonArea>
          <StyledRadioButton
            name={id}
            id={`disagree-${id}`}
            label={disagreeLabel}
            disabled={!isDownloaded}
            handleClick={(
              event: React.MouseEvent<HTMLLabelElement, MouseEvent>,
            ) => handleLabelClick(event, false)}
          />
          <StyledRadioButton
            name={id}
            id={`agree-${id}`}
            label={agreeLabel}
            disabled={!isDownloaded}
            handleClick={(
              event: React.MouseEvent<HTMLLabelElement, MouseEvent>,
            ) => handleLabelClick(event, true)}
          />
        </StyledRadioButtonArea>
      </>
    </DownloadDocumentWrapper>
  );
};

export default DownloadRadioButtonWrapper;
