import { useCallback, useState, useMemo, useEffect } from 'react';
import { GDPRObjectType } from '../../../context/ProductProvider';
import { useGetProducts } from '../../../context/ProductProvider/useProducts';
import { getCheckedList, isGDPRObject } from '../utils';

const getSelectedGDPRObjects = (
  gdprObjects: GDPRObjectType[],
  selectedIds: string[],
) => {
  return selectedIds.reduce<GDPRObjectType[]>((prev, selectedId) => {
    const selectedCheckbox = gdprObjects.find(
      (gdprObject) => gdprObject.uniqueId === selectedId,
    );
    if (selectedCheckbox) {
      return [...prev, selectedCheckbox];
    }
    return prev;
  }, []);
};

const useGDPR = () => {
  const { data } = useGetProducts();
  const [selectedGDPRIds, setSelectedGDPRIds] = useState<string[]>([]);

  const gdprObjects = useMemo(
    () => data?.filter<GDPRObjectType>(isGDPRObject) || [],
    [data],
  );

  const mainGDPRObject = gdprObjects.find(
    (x) =>
      x.consent_object.sort_order === 10 || x.consent_object.sort_order === 1,
  );

  const onCheckboxCheck = useCallback(
    (id: string) => {
      setSelectedGDPRIds(getCheckedList(selectedGDPRIds, id));
      if (
        mainGDPRObject &&
        selectedGDPRIds.includes(mainGDPRObject.uniqueId) &&
        id === mainGDPRObject.uniqueId
      ) {
        setSelectedGDPRIds([]);
      }
    },
    [mainGDPRObject, selectedGDPRIds],
  );

  const onSelectAll = (select: boolean) => {
    if (!select) {
      setSelectedGDPRIds([]);
    } else {
      setSelectedGDPRIds(gdprObjects.map((gdprObject) => gdprObject.uniqueId));
    }
  };

  const selectedGDPRObjects = getSelectedGDPRObjects(
    gdprObjects,
    selectedGDPRIds,
  );

  return {
    gdprObjects,
    onCheckboxCheck,
    onSelectAll,
    selectedGDPRIds,
    selectedGDPRObjects,
  };
};

export { useGDPR };
