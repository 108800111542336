import React from 'react';
import styled from 'styled-components';
import StyledContent from '../../layout/StyledContent';
import { ReactComponent as Contact } from './assets/contact.svg';
import StyledIcon from '../../ui/StyledIcon';
import useContent from '../../../context/ContentProvider/useContent';

const StyledContactSection = styled.section`
  background-color: ${(props) => props.theme.color.tertiary};
  padding: 5px;
`;

const StyledContactText = styled.p`
  font-weight: 600;
  font-size: 24px;
  color: ${(props) => props.theme.color.primary};
`;

const StyledIconTextContainer = styled.div`
  display: flex;

  @media screen and (max-width: 799px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledContactTextContainer = styled.div`
  margin-left: 30px;
`;

const Subfooter: React.FC<{}> = () => {
  const { data } = useContent();
  return (
    <StyledContactSection>
      <StyledContent>
        <StyledIconTextContainer>
          <StyledIcon src={Contact} />
          <StyledContactTextContainer>
            <StyledContactText>
              {data?.footer?.subfooter?.text}
            </StyledContactText>
            <StyledContactText>
              {`Kontakt: ${data?.footer?.subfooter?.phoneNumber}, ${data?.footer?.subfooter?.emailAddress}`}
            </StyledContactText>
          </StyledContactTextContainer>
        </StyledIconTextContainer>
      </StyledContent>
    </StyledContactSection>
  );
};

export default Subfooter;
