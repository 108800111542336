import { useCallback, useState, useMemo } from 'react';
import {
  DocumentRadioButtonType,
  ProductUpdateType,
} from '../../../context/ProductProvider';
import { useGetProducts } from '../../../context/ProductProvider/useProducts';
import {
  isDocumentRadioButtonType,
  getRadioButtonList,
  isProductUpdateType,
} from '../utils';

const getSelectedDocumentRadioButtonObjects = (
  radioButtonObjects: (DocumentRadioButtonType | ProductUpdateType)[],
  selectedIds: string[],
) => {
  return selectedIds.reduce<(DocumentRadioButtonType | ProductUpdateType)[]>(
    (prev, selectedId) => {
      const selectedRadioButton = radioButtonObjects.find(
        (radioButton: DocumentRadioButtonType | ProductUpdateType) =>
          radioButton.uniqueId === selectedId,
      );
      if (selectedRadioButton) {
        return [...prev, selectedRadioButton];
      }
      return prev;
    },
    [],
  );
};
const useDocumentRadioButton = () => {
  const { data } = useGetProducts();
  const [selectedDocumentRadioButtonIds, setSelectedDocumentRadioButtonIds] =
    useState<string[]>([]);

  const radiobuttonObjects = useMemo(() => {
    const allObjects: (DocumentRadioButtonType | ProductUpdateType)[] = [];
    const productUpdateObjects =
      data?.filter<ProductUpdateType>(isProductUpdateType) || [];
    const documentRadioButtonObjects =
      data?.filter<DocumentRadioButtonType>(isDocumentRadioButtonType) || [];
    return allObjects
      .concat(documentRadioButtonObjects)
      .concat(productUpdateObjects);
  }, [data]);

  const onDocumentRadioButtonAgree = useCallback(
    (id: string) => {
      setSelectedDocumentRadioButtonIds(
        getRadioButtonList(selectedDocumentRadioButtonIds, id),
      );
    },
    [selectedDocumentRadioButtonIds],
  );

  const onDocumentRadioButtonDisagree = useCallback(
    (id: string) => {
      setSelectedDocumentRadioButtonIds(
        getRadioButtonList(selectedDocumentRadioButtonIds, id, true),
      );
    },
    [selectedDocumentRadioButtonIds],
  );

  const selectedDocumentRadioButtonObjects =
    getSelectedDocumentRadioButtonObjects(
      radiobuttonObjects,
      selectedDocumentRadioButtonIds,
    );

  return {
    radiobuttonObjects,
    onDocumentRadioButtonAgree,
    onDocumentRadioButtonDisagree,
    selectedDocumentRadioButtonIds,
    selectedDocumentRadioButtonObjects,
  };
};

export { useDocumentRadioButton };
