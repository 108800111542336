import { useQuery } from '@tanstack/react-query';
import { Content } from './types';
import axios from 'axios';
import { getContentURL } from '../../lib/url-helper';

const getContent = async () => {
  return (await axios.get<Content>(getContentURL('content.json'))).data;
};

export default function useContent() {
  return useQuery(['content'], getContent, { refetchOnMount: false });
}
