import React from 'react';
import { Outlet } from 'react-router-dom';
import Theme from '../../context/Theme';
import GlobalStyle from './GlobalStyle';
import AuthProvider from '../../context/AuthProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAPIUrl, getContentURL } from '../../lib/url-helper';
import { Helmet } from 'react-helmet';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ProductSummaryProvider from '../../context/ProductProvider/ProductSummaryProvider';

export const LOGIN_URL = `${getAPIUrl()}/auth/login`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const faviconURL = getContentURL('favicon.ico');
  const safariLogoUrl = getContentURL('logo192.png');
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <Helmet>
        <link rel="icon" id="favicon" href={faviconURL} />
        <link rel="apple-touch-icon" href={safariLogoUrl} />
      </Helmet>
      <AuthProvider>
        <Theme>
          <GlobalStyle />
          <ToastContainer
            position={toast.POSITION.BOTTOM_CENTER}
            draggable={false}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
          />
          <ProductSummaryProvider>
            <Outlet />
          </ProductSummaryProvider>
        </Theme>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
