import React, { useState } from 'react';
import styled from 'styled-components';
import StyledModal from '../../../../ui/StyledModal';
import { StyledLink } from './StyledLink';


interface AdditionalInformationModalProps {
  imgUrl: string;
  moreText: string;
}

const StyledImage = styled.img`
  max-width: 90vw;
`

const AdditionalInformationModal = ({ imgUrl, moreText } : AdditionalInformationModalProps) => {
    const [showModal, setShowModal] = useState(false); 
    return (
        <>
        <StyledLink onClick={()=> setShowModal(true)}>{moreText}</StyledLink>
        <StyledModal show={showModal} handleClose={() => setShowModal(false)}>
            <StyledImage src={imgUrl}/> 
        </StyledModal> 
        </>
    );
  };

export default AdditionalInformationModal