import React from 'react';
import StyledList from '../../../ui/StyledList';
import { InputType } from '../../../../context/ProductProvider';
import TextInput from '../../../products/TextInput';

type InputSectionProps = {
  inputObjects: InputType[];
  onCheckboxCheck: (type: string) => void;
  selectedIds: string[];
};

const InputSection = ({
  inputObjects,
  onCheckboxCheck,
  selectedIds,
}: InputSectionProps) => {
  const isCheckboxChecked = (id: string) => {
    return selectedIds.includes(id);
  };

  return (
    <StyledList>
      {inputObjects.map((product) => {
        const { title, description, icon_type, required, validation } =
          product.consent_object;
        return (
          <TextInput
            title={title}
            description={description}
            initialValue={product.custom_data}
            icon={icon_type}
            id={product.uniqueId}
            type={validation}
            required={required}
            key={product.uniqueId}
            onCheckboxCheck={onCheckboxCheck}
            isChecked={isCheckboxChecked(product.uniqueId)}
          />
        );
      })}
    </StyledList>
  );
};

export default InputSection;
