import styled from 'styled-components';

interface StyledSelectProps {
  width?: string;
  maxWidth?: string;
}

const StyledSelect = styled.select<StyledSelectProps>`
  padding: 10px;
  border-radius: 5px;
  border: solid 1px ${(props) => props.theme.color.secondaryButton};
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
`;

export default StyledSelect;
