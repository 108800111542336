import React from 'react';
import { ReactComponent as Card } from './assets/giro.svg';
import { ReactComponent as Bank } from './assets/depot.svg';
import { ReactComponent as Locker } from './assets/locker.svg';
import { ReactComponent as Mail } from './assets/ePoBox.svg';
import { ReactComponent as Document } from './assets/eBankStatement.svg';
import { ReactComponent as Triangle } from './assets/dataForwarding.svg';
import { ReactComponent as Contract } from './assets/dsgvo.svg';
import { ReactComponent as Telephone } from './assets/advertising.svg';
import { ReactComponent as Switch } from './assets/wechsel.svg';
import StyledIcon, { StyledIconWrapperProps } from '../../../ui/StyledIcon';
import { Icon } from '../../../../context/ProductProvider/types';

type ReactSVGComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

const iconSource: Record<Icon, ReactSVGComponent> = {
  CARD: Card,
  BANK: Bank,
  LOCKER: Locker,
  MAIL: Mail,
  DOCUMENT: Document,
  TRIANGLE: Triangle,
  CONTRACT: Contract,
  TELEPHONE: Telephone,
  SWITCH: Switch,
};

interface ProductIconProps extends StyledIconWrapperProps {
  icon: Icon | null;
}

const ProductIcon: React.FC<ProductIconProps> = ({ icon, ...rest }) => {
  if (!icon) return null;
  return (
    <>
      <StyledIcon src={iconSource[icon]} {...rest} data-testid={icon} />
    </>
  );
};

export default ProductIcon;
