import React, { useMemo } from 'react';
import styled from 'styled-components';
import useContent from '../../../../context/ContentProvider/useContent';
import StyledButton from '../../../ui/StyledButton';
import StyledModal from '../../../ui/StyledModal';
import StyledIcon from '../../../ui/StyledIcon';
import { ReactComponent as Information } from '../../assets/information.svg';
import { AdvertisementType } from '../../../../context/ProductProvider';
import { useGetProducts } from '../../../../context/ProductProvider/useProducts';
import { isAdvertisementType } from '../../utils';
import { useAuth } from '../../../../context/AuthProvider';
import { useQueryClient } from '@tanstack/react-query';

const StyledModalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  p {
    margin: 0 0 0 20px;
  }
`;

const StyledModalInfo = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

type ThanksModalProps = {
  showModal: boolean;
  onCloseClick: () => void;
};

const ThanksModal = ({ showModal, onCloseClick }: ThanksModalProps) => {
  const { data } = useContent();
  const queryClient = useQueryClient();
  const { data: products } = useGetProducts();
  const { doLogout } = useAuth();

  const advertisementProducts = useMemo(
    () => products?.filter<AdvertisementType>(isAdvertisementType) || [],
    [products],
  );

  const handleClick = () => {
    if (data?.modal.backToStartLink) {
      window.location.href = data.modal.backToStartLink;
    } else {
      onCloseClick();
      queryClient.clear();
      doLogout();
    }
  };
  const backToStartButton = data?.modal.backToStartButton
    ? [
        <StyledButton key="backToOverview" onClick={handleClick}>
          {data?.modal.backToStartButton}
        </StyledButton>,
      ]
    : undefined;

  return (
    <StyledModal
      show={showModal}
      title={data?.modal.thanksTitle}
      details={data?.modal.thanksDetails}
      // should not be closed by clicking the background
      handleClose={() => {}}
      buttons={backToStartButton}
    >
      {!!advertisementProducts.length && (
        <StyledModalInfoContainer>
          {advertisementProducts.map((entry) => (
            <StyledModalInfo key={entry.uniqueId}>
              <StyledIcon
                src={Information}
                alt="Information"
                title={''}
                color="#262626"
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: entry.consent_object.description,
                }}
              />
            </StyledModalInfo>
          ))}
        </StyledModalInfoContainer>
      )}
    </StyledModal>
  );
};

export default ThanksModal;
