import React, { useState } from 'react';
import StyledCheckbox from '../../ui/StyledCheckbox';
import StyledLabel from '../../ui/StyledLabel';
import { CheckboxPropsInterface } from '../Checkbox';
import useContent from '../../../context/ContentProvider/useContent';
import DownloadDocumentWrapper from '../shared/DownloadDocumentWrapper';

export interface DocumentCheckboxProps
  extends Omit<CheckboxPropsInterface, 'informationText'> {
  description: string;
  infoUrl: string;
}

const DocumentCheckbox: React.FC<DocumentCheckboxProps> = ({
  title,
  id,
  labelText = 'Auswählen',
  icon,
  description,
  required,
  isChecked,
  infoUrl,
  onCheckboxCheck,
}) => {
  const [isDownloaded, setIsDownloaded] = useState(false);
  const { data } = useContent();
  return (
    <DownloadDocumentWrapper
      infoUrl={infoUrl}
      icon={icon}
      title={title}
      description={description}
      onDownloaded={() => setIsDownloaded(true)}
      required={required}
    >
      <StyledLabel disabled={!isDownloaded}>
        <StyledCheckbox
          required={required}
          checked={isChecked}
          disabled={!isDownloaded}
          onChange={() => onCheckboxCheck(id)}
        />
        {data?.modal.documentCheckboxSelectText || labelText}
      </StyledLabel>
    </DownloadDocumentWrapper>
  );
};

export default DocumentCheckbox;
