import React from 'react';
import StyledList from '../../../ui/StyledList';
import {
  DocumentRadioButtonType,
  ProductOption,
  ProductUpdateType,
} from '../../../../context/ProductProvider';
import DocumentRadioButton from '../../../products/DocumentRadioButton';
import { isDocumentRadioButtonType, isProductUpdateType } from '../../utils';
import ProductUpdateRadioButton from '../../../products/ProductUpdateRadioButton';

type DocumentRadioButtonSectionProps = {
  documentRadioButtonObjects: (DocumentRadioButtonType | ProductUpdateType)[];

  onDocumentRadioButtonCheckAgree: (type: string) => void;
  onDocumentRadioButtonCheckDisagree: (type: string) => void;
};

const RadioButtonSection = ({
  documentRadioButtonObjects,
  onDocumentRadioButtonCheckAgree,
  onDocumentRadioButtonCheckDisagree,
}: DocumentRadioButtonSectionProps) => {
  return (
    <StyledList>
      {documentRadioButtonObjects.map((product) => {
        const { title, description, icon_type, info_url } =
          product.consent_object;
        if (isDocumentRadioButtonType(product)) {
          return (
            <DocumentRadioButton
              title={title}
              description={description}
              icon={icon_type}
              infoUrl={info_url}
              id={product.uniqueId}
              key={product.uniqueId}
              radioButtonText={product.custom_data}
              onAgreeSelect={onDocumentRadioButtonCheckAgree}
              onDisagreeSelect={onDocumentRadioButtonCheckDisagree}
            />
          );
        }

        if (isProductUpdateType(product)) {
          const firstProductTitle = product.consent_object.options.find(
            (x: ProductOption) => x.order === 0,
          );
          const secondProductTitle = product.consent_object.options.find(
            (x: ProductOption) => x.order === 1,
          );
          return (
            <ProductUpdateRadioButton
              id={product.uniqueId}
              description={description}
              title={title}
              infoUrl={info_url}
              icon={icon_type}
              onAgreeSelect={onDocumentRadioButtonCheckAgree}
              onDisagreeSelect={onDocumentRadioButtonCheckDisagree}
              accountNumber={product.account_number}
              firstProductTitle={firstProductTitle?.title}
              secondProductTitle={secondProductTitle?.title}
            />
          );
        }
        return null;
      })}
    </StyledList>
  );
};

export default RadioButtonSection;
