import React from 'react';
import NumberFormat from '../../../../lib/NumberFormat';
import styled from 'styled-components';
import useContent from '../../../../context/ContentProvider/useContent';

const StyledBonusContainer = styled.div`
  background-color: ${(props) => props.theme.color.white};
  border-radius: 10px;
  box-shadow: 7px 13px 20px -8px rgba(0,0,0,0.46);
  width: max-content;
  height: max-content;
  align-self: center;
  margin-bottom: 1rem;
`;

const BonusText = styled.p`
  color: ${(props) => props.theme.color.black};
  font-weight: 700;
  padding: 5px 15px;
  margin: 0;
`;

interface BonusProps {
  bonus: number;
}

const Bonus: React.FC<BonusProps> = ({ bonus }) => {
  const { data } = useContent();
  const suffix = data?.product.bonus ? data?.product.bonus : '';
  return (
    <StyledBonusContainer>
      <BonusText>
        <NumberFormat prefix={'- '} price={bonus} suffix={suffix} />
      </BonusText>
    </StyledBonusContainer>
  );
};

export default Bonus;
