import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  // useProducts,
  DefaultType,
  DropdownType,
  ProductOption,
} from '../../../context/ProductProvider';
import { useGetProducts } from '../../../context/ProductProvider/useProducts';
import { isCardObject, isDefaultCard, isDropdownCard } from '../utils';

export type DropdownSelection = Record<string, string>;
export type DropdownSelectionObject = Record<string, ProductOption>;
export type ChangeDropdownSelection = (
  dropdownId: string,
  selectedValue: string,
) => void;

const getDefaultDropdownSelections = (dropdownCards: DropdownType[]) => {
  return dropdownCards.reduce<DropdownSelection>((prev, card) => {
    if (card.consent_object_option) {
      prev[card.uniqueId] = card.consent_object_option.id;
    } else {
      prev[card.uniqueId] = card.consent_object.options[0].id;
    }
    return prev;
  }, {});
};

const getSelectedDropdownOptionObjects = (
  dropdownCards: DropdownType[],
  dropdownSelections: DropdownSelection,
) => {
  return dropdownCards.reduce<DropdownSelectionObject>((prev, curr) => {
    const selectedOption = dropdownSelections[curr.uniqueId];
    const option = curr.consent_object.options.find(
      (option) => option.id === selectedOption,
    );
    if (option) {
      prev[curr.uniqueId] = option;
    }
    return prev;
  }, {});
};

const useCards = () => {
  const { data } = useGetProducts();
  const [dropdownSelections, setDropdownSelections] =
    useState<DropdownSelection>({});

  const cardsData = useMemo(() => {
    return data?.filter<DefaultType | DropdownType>(isCardObject) || [];
  }, [data]);

  const dropdownCards = useMemo(() => {
    return data?.filter<DropdownType>(isDropdownCard) || [];
  }, [data]);

  const defaultCards = useMemo(() => {
    return data?.filter<DefaultType>(isDefaultCard) || [];
  }, [data]);

  useEffect(() => {
    if (dropdownCards?.length) {
      setDropdownSelections(getDefaultDropdownSelections(dropdownCards));
    }
  }, [dropdownCards]);

  const changeDropdownSelection = useCallback(
    (dropdownId: string, selectedValue: ProductOption['id']) => {
      setDropdownSelections((selections) => ({
        ...selections,
        [dropdownId]: selectedValue,
      }));
    },
    [],
  );

  const selectedDropdownOptionsObject = getSelectedDropdownOptionObjects(
    dropdownCards,
    dropdownSelections,
  );

  return {
    dropdownSelections,
    cardsData,
    defaultCards,
    changeDropdownSelection,
    selectedDropdownOptionsObject,
  };
};

export { useCards };
