import { useCallback, useState, useMemo } from 'react';
import { InputType } from '../../../context/ProductProvider';
import { useGetProducts } from '../../../context/ProductProvider/useProducts';
import {isObjectRequired, getCheckedList, isInputType } from '../utils';

const getSelectedOptionalInputObjects = (
  inputObjects: InputType[],
  selectedIds: string[],
) => {
  return selectedIds.reduce<InputType[]>((prev, selectedId) => {
    const selectedInput = inputObjects.find(
      (input) => input.uniqueId === selectedId,
    );
    if (selectedInput) {
      return [...prev, selectedInput];
    }
    return prev;
  }, []);
};

const useInput = () => {
  const { data } = useGetProducts();
  const [selectedInputCheckboxIds, setSelectedInputCheckboxIds] = useState<string[]>([]);

  const inputObjects = useMemo(
    () => data?.filter<InputType>(isInputType) || [],
    [data],
  );
  const requiredInput = useMemo(
    () => inputObjects?.filter(isObjectRequired) || [],
    [inputObjects],
  );
  const optionalInput = useMemo(
    () =>
    inputObjects?.filter((product) => !isObjectRequired(product)) || [],
    [inputObjects],
  );

  const onInputCheckboxCheck = useCallback(
    (id: string) => {
      setSelectedInputCheckboxIds(getCheckedList(selectedInputCheckboxIds, id));
    },
    [selectedInputCheckboxIds],
  );

  const selectedOptionalInputObjects = getSelectedOptionalInputObjects(
    optionalInput,
    selectedInputCheckboxIds,
  );

  return {
    requiredInput,
    optionalInput,
    onInputCheckboxCheck,
    selectedInputCheckboxIds,
    selectedOptionalInputObjects
  };
};

export { useInput };
