import React, { ChangeEvent, useContext } from 'react';
import styled from 'styled-components';
import RecommendationSelect, {
  RecommendationElementProps,
} from '../RecommendationSelect';
import AdditionalInformationComponent, {
  AdditionalInformationComponentProps,
} from '../shared/AdditionalInformationComponent';
import Card from '../shared/Card';
import PriceArea from '../shared/PriceArea';
import { PriceIntervals, Icon } from '../../../context/ProductProvider';
import { ChangeDropdownSelection } from '../../ConsentArea/hooks/useCards';
import useContent from '../../../context/ContentProvider/useContent';

export interface AdditionalDropdownCardProps {
  icon: Icon;
  title: string;
  numberText: string;
  digits: string;
  lastFourDigitsOnly: boolean;
  price?: number;
  priceInterval: PriceIntervals;
  selectedText?: string;
  options: RecommendationElementProps[];
  selected: string;
  changeDropdownSelection: ChangeDropdownSelection;
  id: string;
  bonus?: number;
}

export type DropdownCardProps = AdditionalDropdownCardProps &
  AdditionalInformationComponentProps;

const DropdownCardContainer = styled.div`
  display: flex;
  padding: 30px 0;
  border-bottom: 1px solid white;
  justify-content: space-between;
  &:first-of-type {
    padding-top: 0;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  @media screen and (max-width: 568px) {
    wrap: unwrap;
  }
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;
`;

const DropdownCard: React.FC<DropdownCardProps> = ({
  id,
  options,
  title,
  bonus,
  numberText,
  digits,
  icon,
  lastFourDigitsOnly,
  priceInterval,
  informationUrl,
  selected,
  changeDropdownSelection,
}) => {
  const price = options.find(({ id }) => id === selected)?.price;
  const onSelectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    changeDropdownSelection(id, e.target.value);
  };
  const content = useContent();

  return (
    <DropdownCardContainer>
      <Card
        title={title}
        icon={icon}
        numberText={numberText}
        digits={digits}
        lastFourDigitsOnly={lastFourDigitsOnly}
      />
      <StyledColumn>
        <RecommendationSelect
          value={selected}
          options={options}
          onSelectionChange={onSelectionChange}
        />
        <PriceArea
          text={content.data?.product.dropdownPriceTitle}
          price={price!}
          priceInterval={priceInterval}
          prefix={content.data?.product.dropdownPricePrefix}
          bonus={bonus}
        />
        <AdditionalInformationComponent informationUrl={informationUrl} />
      </StyledColumn>
    </DropdownCardContainer>
  );
};

export default DropdownCard;
