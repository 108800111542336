import styled from 'styled-components';

const StyledLabel = styled.label<{ disabled?: boolean; border?: boolean }>`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.secondary};
  user-select: none;
  ${(props) =>
    props.border &&
    `border: 1px solid ${props.theme.color.secondary}; border-radius: 4px; padding: 15px 50px;`}
  ${(props) => props.disabled && `color: ${props.theme.color.inputDisabled};`}
`;

export default StyledLabel;
