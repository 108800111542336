import axios, { AxiosError } from 'axios';
import { getAPIUrl } from '../lib/url-helper';

class Info extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Info';
  }
}

export const errorMessages = {
  incorrectUserData:
    'Bitte geben Sie Ihre korrekte Zugangs-ID und Ihren korrekten Zugangscode aus dem Anschreiben ein.',
  maximumLoginAttempts:
    'Sie haben die maximale Anzahl an Login-Versuchen überschritten. Bitte wenden Sie sich an den Support.',
  noMoreConsents: 'Es wird keine weitere Zustimmung benötigt.',
  default:
    'Es ist ein unerwarteter Fehler aufgetreten. Bitte wenden Sie sich an den Support.',
};

export enum ErrorSeverity {
  Error,
  Info,
}

function setErrorMessage(status: number | undefined) {
  switch (status) {
    case 401:
      return {
        type: ErrorSeverity.Error,
        message: errorMessages.incorrectUserData,
      };
    case 403:
      return {
        type: ErrorSeverity.Error,
        message: errorMessages.maximumLoginAttempts,
      };
    case 422:
      return {
        type: ErrorSeverity.Info,
        message: errorMessages.noMoreConsents,
      };
    default:
      return { type: ErrorSeverity.Error, message: errorMessages.default };
  }
}

const apiClient = axios.create({
  withCredentials: true,
  baseURL: getAPIUrl(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  if (token) {
    config.headers = config.headers ?? {};
    config.headers['x-csrftoken'] = token;
  }
  return config;
});

apiClient.interceptors.response.use(
  (res) => res,
  (e) => {
    const error = e as AxiosError;
    const customError = setErrorMessage(error.response?.status);
    if (customError.type === ErrorSeverity.Info) {
      throw new Info(customError.message);
    } else {
      throw new Error(customError.message);
    }
  },
);

export default apiClient;
