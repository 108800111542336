import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Overlay } from '../StyledModal';
import styled from 'styled-components';

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.color.secondary};
  position: absolute;
  left: 50%;
  top: 50%;
`;

const SpinnerOverlay: React.FC<{ show: boolean }> = ({ show }) => {
  return (
    <Overlay show={show}>
      <StyledIcon icon={faSpinner} spin size="4x" />
    </Overlay>
  );
};

export default SpinnerOverlay;
