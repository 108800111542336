import React from 'react';
import styled from 'styled-components';
import AdditionalInformationComponent, {
  AdditionalInformationComponentProps,
} from '../shared/AdditionalInformationComponent';
import Card from '../shared/Card';
import PriceArea from '../shared/PriceArea';
import { PriceIntervals, Icon } from '../../../context/ProductProvider';
import useContent from '../../../context/ContentProvider/useContent';

export interface AdditionalPreselectedCardProps {
  icon: Icon;
  title: string;
  numberText: string;
  digits: string;
  lastFourDigitsOnly: boolean;
  priceInterval: PriceIntervals;
  price: number;
  bonus?: number;
}

export type PreselectedCardProps = AdditionalPreselectedCardProps &
  AdditionalInformationComponentProps;

const PreselectedCardContainer = styled.div`
  display: flex;
  padding: 30px 0;
  border-bottom: 1px solid white;
  justify-content: space-between;
  &:first-of-type {
    padding-top: 0;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 568px) {
    wrap: unwrap;
  }
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  @media screen and (max-width: 799px) {
    min-width: 200px;
  }
`;

const PreselectedCard: React.FC<PreselectedCardProps> = ({
  title,
  numberText,
  digits,
  icon,
  lastFourDigitsOnly,
  price,
  bonus,
  priceInterval,
  informationUrl,
}) => {
  const content = useContent();
  return (
    <PreselectedCardContainer>
      <Card
        title={title}
        icon={icon}
        numberText={numberText}
        digits={digits}
        lastFourDigitsOnly={lastFourDigitsOnly}
      />
      <StyledColumn>
        <PriceArea
          text={content.data?.product.defaultPriceTitle}
          price={price}
          bonus={bonus}
          priceInterval={priceInterval}
          prefix={content.data?.product.defaultPricePrefix}
        />
        <AdditionalInformationComponent informationUrl={informationUrl} />
      </StyledColumn>
    </PreselectedCardContainer>
  );
};

export default PreselectedCard;
