export const getAPIUrl = (): string => {
    const env_url = `${process.env.REACT_APP_API_URL}`;
        // for localhost and test etc
    if (env_url.startsWith('http')) return env_url;
    const window_url =  window.location.host;
    const [tenant, ...rest] = window_url.split('.')
    const url = tenant + '.' + env_url + '.' + rest.join('.')
    return `https://${url}`;

}
export const getContentURL = (filename:string): string => {
    const env_url = `${process.env.REACT_APP_API_URL}`;
    // for localhost and test etc
    if (env_url.startsWith('http')) return `./${filename}`;
    const window_url =  window.location.host;
    const tenant = window_url.split(".")[0];
    return `https://${window_url}/${tenant}/${filename}`;

}