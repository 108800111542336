import styled from 'styled-components';

export const StyledLink = styled.a`
  text-decoration: underline;
  font-size: 15px;
  color: ${(props) => props.theme.color.primary};
  margin: auto 0 0 0;

  @media screen and (max-width: 799px) {
    margin: 0;
  }
`;
