import React, { FormEvent, useEffect } from 'react';
import styled from 'styled-components';
import StyledContent from '../layout/StyledContent';
import ConsentSelection from '../products/ConsentSelection';
import StyledH2 from '../ui/StyledH2';
import { CardSection, CheckboxSection, Modal, ThanksModal } from './components';
import {
  useConsentModals,
  useCheckbox,
  useCards,
  useDocumentCheckbox,
} from './hooks';
import {
  useUpdateProducts,
  useGetProducts,
} from '../../context/ProductProvider/useProducts';
import { getMappedProducts } from '../../context/ProductProvider/utils';
import { toast } from 'react-toastify';
import SpinnerOverlay from '../ui/SpinnerOverlay';
import DocumentCheckboxSection from './components/DocumentCheckboxSection';
import useContent from '../../context/ContentProvider/useContent';
import InputSection from './components/InputSection';
import { useInput } from './hooks/useInput';
import { useDocumentRadioButton } from './hooks/useRadioButton';
import RadioButtonSection from './components/RadioButtonSection';
import { isAnyProductPresent } from './utils';
import GDPRSection from './components/GDPRSection/GDPRSection';
import { useGDPR } from './hooks/useGDPR';

const StyledConsentSection = styled.section`
  background-color: ${(props) => props.theme.color.tertiary};
  box-sizing: border-box;
`;

const ConsentArea = () => {
  const {
    requiredCheckbox,
    optionalCheckbox,
    onCheckboxCheck,
    selectedCheckboxIds,
    selectedCheckboxObjects,
  } = useCheckbox();
  const {
    requiredDocumentCheckbox,
    optionalDocumentCheckbox,
    onDocumentCheckboxCheck,
    selectedDocumentCheckboxIds,
    selectedDocumentCheckboxObjects,
  } = useDocumentCheckbox();
  const {
    dropdownSelections,
    changeDropdownSelection,
    cardsData,
    defaultCards,
    selectedDropdownOptionsObject,
  } = useCards();
  const {
    radiobuttonObjects,
    onDocumentRadioButtonAgree,
    onDocumentRadioButtonDisagree,
    selectedDocumentRadioButtonIds,
    selectedDocumentRadioButtonObjects,
  } = useDocumentRadioButton();

  const {
    gdprObjects,
    onCheckboxCheck: gdprCheckboxCheck,
    onSelectAll,
    selectedGDPRIds,
    selectedGDPRObjects,
  } = useGDPR();

  const {
    requiredInput,
    optionalInput,
    selectedInputCheckboxIds,
    onInputCheckboxCheck,
    selectedOptionalInputObjects,
  } = useInput();
  const {
    isModalActive,
    isThanksModalActive,
    showModal,
    closeModal,
    closeThanksModal,
    showThanksModal,
  } = useConsentModals();

  const { data, isFetching } = useGetProducts();
  const content = useContent();

  const { mutate: updateProducts, isLoading: updateLoading } =
    useUpdateProducts({
      onSuccess: () => {
        showThanksModal();
      },
      onError: () => {
        toast.error(
          'Es gab ein Problem beim Übermitteln der Daten. Bitte versuchen Sie es erneut.',
        );
      },
    });

  const showSummaryModal = (e: FormEvent) => {
    e.preventDefault();
    showModal();
  };

  const isConfirmationProductPresent = data?.some(
    (x) => x.consent_object.type === 'CONFIRMATION',
  );

  const handleConfirmClick = async () => {
    if ((data && data.length < 1) || !data) {
      showThanksModal();
      return;
    }
    const ids = [
      ...selectedCheckboxIds,
      ...selectedDocumentCheckboxIds,
      ...selectedInputCheckboxIds,
      ...selectedDocumentRadioButtonIds,
      ...selectedGDPRIds,
    ];
    updateProducts(getMappedProducts(data, ids, selectedDropdownOptionsObject));
  };

  const showOptionalSection = isAnyProductPresent([
    optionalCheckbox,
    optionalDocumentCheckbox,
    optionalInput,
    gdprObjects,
  ]);
  const showRequiredSection = isAnyProductPresent([
    requiredCheckbox,
    requiredDocumentCheckbox,
    requiredInput,
    cardsData,
    radiobuttonObjects,
  ]);

  const selectedTitles = [
    ...defaultCards.map((card) => card.consent_object.title),
    ...Object.values(selectedDropdownOptionsObject).map(
      (option) => option.title,
    ),
    ...selectedCheckboxObjects.map((card) => card.consent_object.title),
    ...selectedDocumentCheckboxObjects.map((card) => card.consent_object.title),
    ...requiredInput.map((input) => input.consent_object.title),
    ...selectedOptionalInputObjects.map((input) => input.consent_object.title),
    ...selectedDocumentRadioButtonObjects.map(
      (selectedRadioButton) => selectedRadioButton.consent_object.title,
    ),
    ...selectedGDPRObjects.map((gdpr) => gdpr.consent_object.title),
  ];

  useEffect(() => {
    const allConsentObjects = [
      ...requiredCheckbox,
      ...cardsData,
      ...requiredDocumentCheckbox,
      ...optionalCheckbox,
      ...optionalDocumentCheckbox,
      ...requiredInput,
      ...optionalInput,
      ...radiobuttonObjects,
      ...gdprObjects,
    ];
    if (!isFetching && allConsentObjects.length < 1) {
      if (isConfirmationProductPresent) {
        showModal();
      } else {
        showThanksModal();
      }
    } else {
      closeModal();
    }
  }, [
    showModal,
    closeModal,
    requiredCheckbox,
    cardsData,
    requiredDocumentCheckbox,
    optionalCheckbox,
    optionalDocumentCheckbox,
    isFetching,
    requiredInput,
    optionalInput,
    radiobuttonObjects,
    isConfirmationProductPresent,
    showThanksModal,
    gdprObjects,
  ]);

  return (
    <>
      <SpinnerOverlay show={!!isFetching} />
      <StyledConsentSection>
        <StyledContent>
          <form onSubmit={showSummaryModal}>
            {showRequiredSection && (
              <>
                <StyledH2>{content.data!.product.mandatory}</StyledH2>
                <CardSection
                  dropdownSelections={dropdownSelections}
                  changeDropdownSelection={changeDropdownSelection}
                  data={cardsData}
                />
                <DocumentCheckboxSection
                  documentCheckboxObjects={requiredDocumentCheckbox}
                  onDocumentCheckboxCheck={onDocumentCheckboxCheck}
                  selectedIds={selectedDocumentCheckboxIds}
                />
                <CheckboxSection
                  checkboxObjects={requiredCheckbox}
                  onCheckboxCheck={onCheckboxCheck}
                  selectedIds={selectedCheckboxIds}
                />
                <InputSection
                  inputObjects={requiredInput}
                  // no checkbox for required input
                  onCheckboxCheck={() => {}}
                  selectedIds={[]}
                />
                <RadioButtonSection
                  documentRadioButtonObjects={radiobuttonObjects}
                  onDocumentRadioButtonCheckAgree={onDocumentRadioButtonAgree}
                  onDocumentRadioButtonCheckDisagree={
                    onDocumentRadioButtonDisagree
                  }
                />
              </>
            )}
            {showOptionalSection && (
              <>
                <StyledH2>{content.data!.product.optional}</StyledH2>
                <CheckboxSection
                  checkboxObjects={optionalCheckbox}
                  onCheckboxCheck={onCheckboxCheck}
                  selectedIds={selectedCheckboxIds}
                />
                <DocumentCheckboxSection
                  documentCheckboxObjects={optionalDocumentCheckbox}
                  onDocumentCheckboxCheck={onDocumentCheckboxCheck}
                  selectedIds={selectedDocumentCheckboxIds}
                />
                <InputSection
                  inputObjects={optionalInput}
                  onCheckboxCheck={onInputCheckboxCheck}
                  selectedIds={selectedInputCheckboxIds}
                />
                {gdprObjects.length > 0 && (
                  <GDPRSection
                    gdprObjects={gdprObjects}
                    onCheckboxCheck={gdprCheckboxCheck}
                    selectedIds={selectedGDPRIds}
                    onSelectForAll={onSelectAll}
                  />
                )}
              </>
            )}
            <ConsentSelection onConfirmClick={() => {}} />
          </form>
        </StyledContent>
      </StyledConsentSection>
      <Modal
        loading={updateLoading}
        showModal={isModalActive}
        onCloseClick={closeModal}
        onConfirmClick={handleConfirmClick}
        selectedTitles={selectedTitles}
      />
      <ThanksModal
        showModal={isThanksModalActive}
        onCloseClick={closeThanksModal}
      />
    </>
  );
};

export default ConsentArea;
