import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useContent from '../../context/ContentProvider/useContent';
import {
  useUpdateProducts,
  useGetProducts,
} from '../../context/ProductProvider/useProducts';
import { isDocumentObject } from '../ConsentArea/utils';
import { DocumentType } from '../../context/ProductProvider/types';
import DocumentModal from './DocumentModal';
import { AppPath } from '../..';
import SpinnerOverlay from '../ui/SpinnerOverlay';
import { getMappedDocument } from '../../context/ProductProvider/utils';

const Documents: React.FC = () => {
  const { data: content } = useContent();
  const navigate = useNavigate();
  const [openedModalId, setOpenedModalId] =
    useState<string | undefined>(undefined);
  const {
    data: products,
    isFetching: fetchingDocuments,
    isSuccess,
  } = useGetProducts('documents');
  const { isLoading: updatingDocuments, mutateAsync: updateDocument } =
    useUpdateProducts();
  const documentObjects = useMemo(
    () => products?.filter<DocumentType>(isDocumentObject) || [],
    [products],
  );

  useEffect(() => {
    if (fetchingDocuments || !isSuccess) {
      return;
    }
    if (documentObjects?.filter((d) => d.status === 0).length === 0) {
      navigate(AppPath.PRODUCTS, { replace: true });
    } else {
      setOpenedModalId(documentObjects[0].consent_object.id);
    }
  }, [fetchingDocuments, isSuccess, documentObjects, navigate]);

  const handleContinue = async (index: number, lastEntry: boolean) => {
    const document = documentObjects?.[index];
    if (!document) {
      return;
    }
    const mappedDocument = getMappedDocument(document);

    await updateDocument([mappedDocument]);

    if (lastEntry) {
      setOpenedModalId(undefined);
      navigate(AppPath.PRODUCTS, { replace: true });
    } else {
      const nextDocument = documentObjects[index + 1];
      setOpenedModalId(
        nextDocument ? nextDocument.consent_object.id : undefined,
      );
    }
  };

  return (
    <>
      {documentObjects?.map(({ consent_object }, i, arr) => {
        return (
          <DocumentModal
            key={i}
            show={openedModalId === consent_object?.id}
            title={consent_object?.title}
            details={consent_object?.description}
            checkboxText={content?.modal.documentCheckboxText}
            infoUrl={consent_object?.info_url}
            handleClose={() => setOpenedModalId(undefined)}
            handleContinueClick={() => handleContinue(i, arr.length - 1 === i)}
            loading={updatingDocuments}
          />
        );
      })}
      <SpinnerOverlay show={!!fetchingDocuments} />
    </>
  );
};

export default Documents;
